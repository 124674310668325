import {Fragment} from "react";
import GoToInfoPage from "../redirects/GoToInfoPage";

const FormatChatMessage = (message, type) => {
    if (!message) {
        return "Dere har ikke startet en chat";
    }
    if (type === "display" && message !== "Dere har ikke startet en chat") {
        return message.length > 15 ? message.slice(0, 15) + "..." : message;
    }

    if (type === "display" && message === "Dere har ikke startet en chat") {
        return message;
    }
    const tagRegex = /#([A-Za-z0-9\s]+),\sid:([a-f0-9]+),\stype:([A-Za-z0-9\s]+)#/g;
    const parts = [];
    let lastIndex = 0;
    message.replace(tagRegex, (match, tagName, tagId, tagType, offset) => {
        if (lastIndex < offset) {
            parts.push(message.slice(lastIndex, offset));
        }

        if (type === "chat") {
            parts.push(
                <Fragment key={tagId}>
                    <span className="chat--tagged-object" onClick={() => GoToInfoPage(tagType, tagId)}>
                        &nbsp;{tagName}
                    </span>
                </Fragment>
            );
        }
        else if (type === "display") {
            parts.push(
                <Fragment key={tagId}>
                    <p>
                        &nbsp;{tagName}
                    </p>
                </Fragment>
            );
        }

        lastIndex = offset + match.length;
    });
    if (lastIndex < message.length) {
        parts.push(message.slice(lastIndex));
    }
    return parts;
};
export default FormatChatMessage;