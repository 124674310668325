import React, {useEffect, useRef, useState} from "react";
import LoginSms from "../../functions/apiCalls/Post/LoginSms";
import HandleSmsInput from "../../functions/inputs/HandleSmsInput";

const SmsCodeLogin = ({smsMode, setSmsMode, identifier, setIdentifier, setPassword, setMessageData, setToken}) => {
    const [smsCode, setSmsCode] = useState({
        num1: '',
        num2: '',
        num3: '',
        num4: ''
    });
    const hiddenInputRef = useRef(null);

    const handleSmsSubmit = async () => {
        try {

            const code = smsCode.num1 + smsCode.num2 + smsCode.num3 + smsCode.num4;
            const data = {
                username: identifier,
                smsCode: code
            }
            await LoginSms(data, setToken, setMessageData, setSmsCode);
        } catch (error) {
            setMessageData({
                type: 'warning',
                message: 'En feil oppstod under SMS-verifisering.'
            });
        }
    };

    useEffect(() => {
        if (smsCode.num1 && smsCode.num2 && smsCode.num3 && smsCode.num4) {
            handleSmsSubmit();
        }
    }, [smsCode]);

    useEffect(() => {
        const handleClickOutside = () => {
            if (smsMode && hiddenInputRef.current) {
                hiddenInputRef.current.focus();
            }
        };
        const timer = setTimeout(() => {
            if (smsMode && hiddenInputRef.current) {
                hiddenInputRef.current.focus();
            }
        }, 0);
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
            clearTimeout(timer);
        };
    }, [smsMode]);

    const GoBackToLogin = () => {
        setSmsMode(false);
        setIdentifier("");
        setPassword("");
        setSmsCode({
            num1: '',
            num2: '',
            num3: '',
            num4: ''
        });
    }

    return (
        <form className="flex--column justify-content-center align-items--center sms-code--form login" onSubmit={handleSmsSubmit}>
            <h2>Sms Vertifisering</h2>
            <div className="sms-code--inputs flex--row justify-content-sb">
                <input
                    type="text"
                    value={smsCode.num1}
                    maxLength={1}
                    readOnly
                />
                <input
                    type="text"
                    value={smsCode.num2}
                    maxLength={1}
                    readOnly
                />
                <input
                    type="text"
                    value={smsCode.num3}
                    maxLength={1}
                    readOnly
                />
                <input
                    type="text"
                    value={smsCode.num4}
                    maxLength={1}
                    readOnly
                />
            </div>
            <div className="flex--row justify-content-center align-items--center">
                <button className="btn btn-primary-white-bg" type="button"
                        onClick={() => GoBackToLogin()}>
                    Tilbake
                </button>
            </div>
            <input
                className="input-hidden"
                type="text"
                ref={hiddenInputRef}
                onKeyUp={(e) => HandleSmsInput(e, setSmsCode)}
            />
        </form>
    );
}
export default SmsCodeLogin;