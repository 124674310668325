const MessageDateFormat = (dateStr) => {
    const date = new Date(dateStr);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
        return "I dag";
    }
    if (date.toDateString() === yesterday.toDateString()) {
        return "I går";
    }
    return date.toLocaleDateString('no-NO');
};

const MessageTimeFormat = (dateStr) => {
    const date = new Date(dateStr);
    const timeNorway = date.toLocaleTimeString('no-NO', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    });
    return timeNorway;
};
export {MessageTimeFormat, MessageDateFormat};