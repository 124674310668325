import PopUpButtons from "./PopUpButtons";

const PopupKummer = ({ item, handleUpdateCoordinates }) => {
    const icon = item.kumType.includes("Veggskap") ? 'icon--kum-wall' : 'icon--kum';
    return (
        <div className="flex--column justify-content-center map--popup">
            <div className="flex--row align-items--center map-popup-header">
                <svg className={icon} />
                <h4>{item.name}</h4>
            </div>
            <div className="flex--row justify-content-sb map--popup-body">
                <div className="flex--column justify-content-center map--popup-info">
                    <div className="flex--row long">
                        <p><b>Status:</b></p>
                        <p>{item.status}</p>
                    </div>
                    <div className="flex--row long">
                        <p><b>Type:</b></p>
                        <p>{item.kumType}</p>
                    </div>
                    <div className="flex--row long">
                        <p><b>Skjøte:</b></p>
                        <p>{item.spliceEnclosure ? item.spliceEnclosure : "Kunne ikke hente"}</p>
                    </div>
                    <div className="flex--column tall">
                        <p><b>Rør Til / Gjennom:</b></p>
                        <p>{item.pipes && item.pipes.length > 0 ? item.pipes.length : "Ingen"}</p>
                    </div>
                    <div className="flex--column tall">
                        <p><b>Kabler Til / Gjennom:</b></p>
                        <p>{item.cables && item.cables.length > 0 ? item.cables.length : "Ingen"}</p>
                    </div>

                </div>
                <PopUpButtons
                    item={item}
                    type="point"
                    endpoint="kum"
                    handleUpdateCoordinates={handleUpdateCoordinates}
                />
            </div>
        </div>
    );
};

export default PopupKummer;
