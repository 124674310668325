import React, { useEffect, useState } from 'react';
import apiUrls from "../../../configs/api";
import FormatDate from "../../../functions/formats-and-regex/formatDate";
import GetById from "../../../functions/apiCalls/Get/GetById";
import GetAll from "../../../functions/apiCalls/Get/GetAll";
import formatDate from "../../../functions/formats-and-regex/formatDate";
import Delete from "../../../functions/apiCalls/Delete";

const Dashboard = ({user}) => {
    const userId = sessionStorage.getItem("userId");
    const [triggerUsers, setTriggerUsers] = useState({});
    const [updates, setUpdates] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [notificationsYou, setNotificationsYou] = useState([]);
    const [notificationsNotYou, setNotificationsNotYou] = useState([]);
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const [bigScreen, setBigScreen] = useState(window.innerWidth > 2000);

    const handleDelete = async (id) => {
        const response = await Delete(apiUrls.delete.deleteUpdate, id);
        if (response) {
            setUpdates((prevUpdates) => prevUpdates.filter((update) => update.id !== id));
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setBigScreen(window.innerWidth > 2000);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (user && user.notifications) {
            setNotifications(user.notifications);
            const notificationsYou = user.notifications.filter(notification => notification.triggerUserId === user.id);
            const notificationsNotYou = user.notifications.filter(notification => notification.triggerUserId !== user.id);
            setNotificationsYou(notificationsYou);
            setNotificationsNotYou(notificationsNotYou);
        }
    }, [user]);

    useEffect(() => {
        const fetchLatestUpdate = async () => {
            try {
                const allUpdates = await GetAll(apiUrls.get.updates);
                if (allUpdates && allUpdates.length > 0) {
                    setUpdates(allUpdates);
                }
            } catch (error) {
                console.error('Error fetching latest update:', error);
            }
        };

        fetchLatestUpdate();
    }, []);

    useEffect(() => {
        const fetchTriggerUsers = async () => {
            try {
                if (user && user.notifications) {
                    const triggerUserIds = user.notifications.map(notification => notification.triggerUserId);
                    if (triggerUserIds && triggerUserIds.length > 0) {
                        const uniqueTriggerUserIds = Array.from(new Set(triggerUserIds));
                        const triggerUsersData = await Promise.all(
                            uniqueTriggerUserIds.map(async (id) => {
                                try {
                                    return await GetById(apiUrls.get.users, id);
                                } catch (error) {
                                    if (error.response && error.response.status === 404) {
                                        return { id, username: "Ukjent Bruker" };
                                    } else {
                                        console.error('Error fetching user:', error);
                                        return null;
                                    }
                                }
                            })
                        );
                        const triggerUsersObject = {};
                        triggerUsersData.forEach((user, index) => {
                            triggerUsersObject[uniqueTriggerUserIds[index]] = user || { id: uniqueTriggerUserIds[index], username: "Ukjent Bruker" };
                        });
                        setTriggerUsers(triggerUsersObject);
                    }
                }
            } catch (error) {
                console.error('Error fetching trigger users data:', error);
            }
        };
        fetchTriggerUsers();
    }, [user]);

    const formatUpdateMessage = (message) => {
        const lines = message.split('\n');
        return lines.map((line, index) => (
            <span key={index}>
            {line}
                <br />
        </span>
        ));
    };

    return (
        <div className="profile--display-page-dashboard flex--row justify-content-sb align-items--center">
            {updates && notifications && user && (
                <>
                    <div className={`profile--dashboard--updates ${notificationsOpen ? 'slim' : 'wide-input'} flex--column align-items--center align-text--center`}>
                        <div className="flex--row align-items--center profile--display-header">
                            <h2>Oppdateringer</h2>
                        </div>
                        <div className="flex--column justify-content-center profile--dashboard--all-updates">
                            {updates.length > 0 ? (
                                updates.sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0, 10).map((update, index) => (
                                    <div className="profile--dashboard-update flex--column align-items--center br" key={index}>
                                        <h3>{update.header}</h3>
                                        <p><b>{update.type}</b></p>
                                        <p className="m-t-10px">{formatUpdateMessage(update.message)}</p>
                                        <p className="date-font m-t-10px">{formatDate(update.date)}</p>
                                        {user.type === "Utvikler" && (
                                            <button onClick={() => handleDelete(update.id)} className="btn btn-delete-white-bg">Slett</button>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div className="profile--dashboard-update">
                                    <h3>Ingen nye oppdateringer</h3>
                                </div>
                            )}
                        </div>
                    </div>

                    {user && user.type !== "Rådgiver Read Only" && (
                        bigScreen ? (
                            <div className={`profile--dashboard--notifications-big-screen flex--row`}>
                                <div className="dashboard--notifications-container">
                                    <div className="profile--display-header-notifications flex--row align-items--center">
                                        <h2>Varsler</h2>
                                    </div>
                                    <div className="flex--row justify-content-sb dashboard--notifications-columns">
                                        <div className="profile--dashboard--all-notifications flex--column justify-content-center align-items--center">
                                            {user && notifications && notifications.length > 0 ? (
                                                notificationsNotYou.slice(-10).reverse().map((notification, index) => (
                                                    <div key={index}
                                                         className="profile--dashboard-notification flex--row justify-content-sb">
                                                        <p className={`triggeruser d-flex justify-content-center align-items--center`}>
                                                            {triggerUsers[notification.triggerUserId]?.username === user.username ? "Du" : triggerUsers[notification.triggerUserId]?.username || "Laster..."}
                                                        </p>
                                                        <p className="message d-flex align-items--center">
                                                            {notification.message}
                                                        </p>
                                                        <p className="date flex--row align-items--center">
                                                            {FormatDate(notification.date)}
                                                        </p>
                                                    </div>
                                                ))
                                            ) : (
                                                <p>Du har ingen varsler for øyeblikket.</p>
                                            )}
                                        </div>
                                        <div className="profile--dashboard--all-notifications">
                                            {user && notifications && notifications.length > 0 ? (
                                                notificationsYou.slice(-10).reverse().map((notification, index) => (
                                                    <div key={index}
                                                         className="profile--dashboard-notification flex--row justify-content-sb">
                                                        <p className={`triggeruser you d-flex justify-content-center align-items--center`}>
                                                            {triggerUsers[notification.triggerUserId]?.username === user.username ? "Du" : triggerUsers[notification.triggerUserId]?.username || "Laster..."}
                                                        </p>
                                                        <p className="message d-flex align-items--center">
                                                            {notification.message}
                                                        </p>
                                                        <p className="date flex--row align-items--center">
                                                            {FormatDate(notification.date)}
                                                        </p>
                                                    </div>
                                                ))
                                            ) : (
                                                <p>Du har ingen varsler for øyeblikket.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={`profile--dashboard--notifications ${notificationsOpen ? 'open' : ''} flex--row`}>
                                <svg className="icon--blue-chevron"
                                     onClick={() => setNotificationsOpen(!notificationsOpen)}/>
                                <div className="dashboard--notifications-container">
                                    <div className="profile--display-header-notifications flex--row align-items--center">
                                        <h2>Varsler</h2>
                                    </div>
                                    <div className="profile--dashboard--all-notifications">
                                        {user && notifications && notifications.length > 0 ? (
                                            notifications.slice(-10).reverse().map((notification, index) => (
                                                <div key={index}
                                                     className="profile--dashboard-notification flex--row justify-content-sb">
                                                    <p className={`triggeruser ${notification.triggerUserId === userId ? 'you' : ''} d-flex justify-content-center align-items--center`}>
                                                        {triggerUsers[notification.triggerUserId]?.username === user.username ? "Du" : triggerUsers[notification.triggerUserId]?.username || "Laster..."}
                                                    </p>
                                                    <p className="message d-flex align-items--center">
                                                        {notification.message}
                                                    </p>
                                                    <p className="date flex--row align-items--center">
                                                        {FormatDate(notification.date)}
                                                    </p>
                                                </div>
                                            ))
                                        ) : (
                                            <p>Du har ingen varsler for øyeblikket.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                </>
            )}
        </div>
    );
};

export default Dashboard;



