import homeownerAssocsStatusOptions from '../../../../assets/data/jsonFiles/HomeownerAssocs/homeownerAssoc_statuses.json';
import {useEffect, useState} from "react";

const NewHomeownerAssocInputs = ({ handleInputChange, formData, setFormData, netadminHomeownerAssocs}) => {
    const [selectedHomeownerAssoc, setSelectedHomeownerAssoc] = useState(null);
    const [loadingText, setLoadingText] = useState('Laster');
    const [loadingDots, setLoadingDots] = useState(0);

    useEffect(() => {
        if (selectedHomeownerAssoc && selectedHomeownerAssoc !== "set-manually") {
            setFormData(prevFormData => ({
                ...prevFormData,
                name: selectedHomeownerAssoc.name,
                address: selectedHomeownerAssoc.address,
                orgNumber: selectedHomeownerAssoc.orgNum,
                apartmentCount: selectedHomeownerAssoc.apartmentCount,
                netadminData: true
            }));
        }
    }, [selectedHomeownerAssoc, setFormData]);

    const handleSelectChange = (event) => {
        const selectedOption = event.target.value;
        if (selectedOption === "set-manually") {
            setSelectedHomeownerAssoc(null);
            setFormData(prevFormData => ({
                ...prevFormData,
                netadminData: false
            }))
        } else {
            const selectedAssoc = netadminHomeownerAssocs.find(assoc => assoc.name === selectedOption);
            setSelectedHomeownerAssoc(selectedAssoc);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setLoadingDots(prevDots => (prevDots + 1) % 4);
        }, 500);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setLoadingText('Laster' + '.'.repeat(loadingDots));
    }, [loadingDots]);

    return (
        <div className="map--add-form-inputs flex--column">
            <div className='flex--row justify-content-sb map--add-form-input-row'>
                <div className="flex--column map--add-form-input-container">
                    <label>Hent Fra NetAdmin</label>
                    {netadminHomeownerAssocs && netadminHomeownerAssocs.length > 0 ? (
                        <select
                            name="netadminHomeownerAssoc"
                            id="netadminHomeownerAssoc"
                            onChange={handleSelectChange}
                        >
                            <option value="set-manually">Fyll ut manuelt</option>
                            {netadminHomeownerAssocs.map((option, index) => (
                                <option value={option.name} key={index}>{option.name}</option>
                            ))}
                        </select>
                    ) : (
                        <select
                            name="netadminHomeownerAssoc"
                            id="netadminHomeownerAssoc"
                            disabled={true}
                        >
                            <option value="">{loadingText}</option>
                        </select>
                    )}
                </div>
                <div className='flex--column map--add-form-input-container'>
                    <label>Org Nummer</label>
                    <input
                        type='text'
                        id='orgNumber'
                        name='orgNumber'
                        placeholder='987654321'
                        value={formData.orgNumber}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                    />
                </div>
            </div>
            <div className='flex--row justify-content-sb map--add-form-input-row'>
                <div className='flex--column map--add-form-input-container'>
                    <label>Navn</label>
                    <input
                        type='text'
                        id='name'
                        name='name'
                        placeholder='Borettslaget'
                        value={formData.name}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                    />
                </div>
                <div className="flex--column map--add-form-input-container">
                    <label>Status</label>
                    <select
                        name="status"
                        id="status"
                        value={formData.status}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                    >
                        {homeownerAssocsStatusOptions.homeownerAssocsStatusOptions.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className='flex--row justify-content-sb map--add-form-input-row'>
                <div className='flex--column map--add-form-input-container'>
                    <label>Adresse</label>
                    <input
                        type='text'
                        id='address'
                        name='address'
                        placeholder='Freserveien 1, 0195 Oslo'
                        value={formData.address}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                    />
                </div>
                <div className='flex--column map--add-form-input-container'>
                    <label>Antall Leiligheter</label>
                    <input
                        type='number'
                        id='apartmentCount'
                        name='apartmentCount'
                        value={formData.apartmentCount}
                        min={1}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                    />
                </div>
            </div>
            <div className='flex--row justify-content-sb map--add-form-input-row'>
                <div className='flex--column whole-row'>
                    <label>Notater</label>
                    <input
                        type='text'
                        id='notes'
                        name='notes'
                        placeholder='Viktig tilleggsinformasjon'
                        value={formData.notes}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                    />
                </div>
            </div>
        </div>
    );
}
export default NewHomeownerAssocInputs;