import { useState, useRef, useEffect } from "react";
import axios from "axios";
import Post from "../../functions/apiCalls/Post/Post";
import apiUrls from "../../configs/api";

const ChatSendMessage = ({ receiverId, usersSessions, setUsersSessions }) => {
    const token = sessionStorage.getItem('token');
    const [message, setMessage] = useState(''); // Plain text for user display
    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [showSearchBox, setShowSearchBox] = useState(false);
    const inputRef = useRef(null);

    const handleSend = async () => {
        if (!message.trim()) return;

        let updatedMessage = message;
        const tagRegex = /#([A-Za-z0-9\s]+)\(([^)]+)\)/g;
        const tags = [...message.matchAll(tagRegex)];

        for (let tag of tags) {
            const tagName = tag[1].trim();
            const tagType = tag[2].trim();

            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
                const response = await axios.get(`${apiUrls.search.search}?keyword=${tagName}`, config);
                const matchingResult = response.data.results.find(
                    (result) =>
                        (result.properties?.name === tagName || result.name === tagName) &&
                        (result.properties?.type === tagType || result.nodeType === tagType || result.kumType === tagType)
                );

                if (matchingResult) {
                    const tagWithId = `#${tagName}, id:${matchingResult.id}, type:${matchingResult.properties?.type || matchingResult.nodeType || matchingResult.kumType}#`;
                    updatedMessage = updatedMessage.replace(tag[0], tagWithId);
                }
            } catch (error) {
                console.log(`Error fetching data for tag ${tagName}:`, error);
            }
        }
        const messageData = {
            message: updatedMessage,
            receiverId: receiverId
        };
        try {
            const response = await Post(apiUrls.chat.sendMessage, messageData);
            const responseData = await response.json();
            if (responseData) {
                const updatedUsersSessions = [...usersSessions];
                const sessionIndex = updatedUsersSessions.findIndex(session => session.messagerId === receiverId);
                if (sessionIndex !== -1) {
                    const updatedSession = updatedUsersSessions[sessionIndex];
                    updatedSession.lastMessage = updatedMessage;
                    updatedSession.lastMessageDate = new Date();
                    updatedSession.lastMessageRead = false;
                    updatedUsersSessions[sessionIndex] = updatedSession;
                    setUsersSessions(updatedUsersSessions);
                }
                setMessage('');
                setSearch('');
                setSearchResults([]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const searchForObject = async (query) => {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };
                const response = await axios.get(`${apiUrls.search.search}?keyword=${query}`, config);
                setSearchResults(response.data.results);
            } catch (e) {
                console.log(e);
            }
        };

        if (search.length >= 2) {
            searchForObject(search);
        } else {
            setSearchResults([]);
        }
    }, [search]);

    const handleSelectResult = (result) => {
        const selectedItemName = result.properties?.name || result.name;
        const selectedItemType = result.properties?.type || result.nodeType || result.kumType;

        const updatedMessageDisplay = `${message} #${selectedItemName}(${selectedItemType})`;

        setMessage(updatedMessageDisplay);
        setSearch('');
        setSearchResults([]);
        setShowSearchBox(false);
    };

    const handleChange = (e) => {
        setMessage(e.target.value);
    };

    const getIcon = (type) => {
        switch (type) {
            case "Minikum":
            case "EnkeltLokk (TK1)":
            case "Dobbeltlokk (TK2)":
            case "TK3":
            case "Rundkum":
                return "kum";
            case "Veggskap (Innvendig)":
            case "Veggskap (Utvendig)":
                return "kum-wall";
            case "Node":
                return "node";
            case "Dist":
                return "node-dist";
            case "Rør":
                return "pipe";
            case "Kabel":
                return "cable";
            case "Borettslag":
                return "homeownerassoc";
            default:
                return "defaultIcon";
        }
    };

    return (
        <div className="chat--input-container flex--row align-items--center">
            <input
                className="chat--input"
                ref={inputRef}
                type="text"
                value={message}
                onChange={handleChange}
                placeholder="Type a message..."
            />

            <div className="chat--input-buttons">
                {showSearchBox && (
                    <div className="chat--search-box">
                        <input
                            type="text"
                            placeholder="Search for an item..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <div className="chat--search-results-container">
                            <div className="chat--search-results">
                                {searchResults.length > 0 ? (
                                    searchResults.map((result) => {
                                        const type = result.properties ? result.properties.type : result.nodeType || result.kumType;
                                        const name = result.properties?.name || result.name;
                                        return (
                                            <p className="flex--row align-items--center" key={result.id} onClick={() => handleSelectResult(result)}>
                                                <svg className={`icon--${getIcon(type)}`} />
                                                {name}
                                            </p>
                                        );
                                    })
                                ) : (
                                    <p>No matches</p>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                <svg className="icon--tag-blue" onClick={() => setShowSearchBox(!showSearchBox)} />
                {!showSearchBox && (
                    <p className="chat--help-box">
                        Klikk her for å tagge objekter i chatten! Bare søk etter objektet og klikk på objektet du vil tagge!
                    </p>
                )}
                <svg className="icon--send-green" onClick={handleSend} />
            </div>
        </div>
    );
};

export default ChatSendMessage;















