import React, {useState, useCallback, useEffect, useRef} from 'react';
import {LayerGroup, Circle, Marker, Popup, GeoJSON} from 'react-leaflet';
import findMarkerCoordinate from "../../../functions/map/FindMarkerCoordinate";
import mapMarkers from "../../iconsAndStyles/MapMarkers";

const RenderPolygonsNetadmin = ({ homeownerAssocs, netadminHomeownerAssocs, style, PopupComponent, hoveredResult }) => {
    const [clickedName, setClickedName] = useState("");
    const markerRef = useRef(null);
    const hoveredName = hoveredResult && (hoveredResult.properties ? hoveredResult.properties.name :  hoveredResult.name);

    useEffect(() => {
        if (hoveredResult && markerRef.current) {
            const marker = markerRef.current;
            marker.fire('click');
            setTimeout(() => {
                marker.fire('click');
            }, 300);
        }
    }, [hoveredResult]);


    const getCircleColor = (apartmentCount) => {
        if (apartmentCount >= 500) return '#278056';
        if (apartmentCount >= 250) return '#ca6500';
        return '#941715'; // Red
    };

    const handleRightClick = useCallback((event, item) => {
        const name = item.properties ? item.properties.name : item.name;
        if (clickedName !== name) {
            setClickedName(name);
        } else {
            setClickedName("");
        }
    }, [clickedName]);

    const renderCircle = (center, radius, color, item, netadmin) => (
        <Circle
            center={center}
            radius={radius}
            color={color}
            fillColor={color}
            fillOpacity={style.fillOpacity}
            weight={style.weight}
            eventHandlers={{
                contextmenu: (event) => handleRightClick(event, item),
            }}
            ref={hoveredName === (item.properties ? item.properties.name : item.name) ? markerRef : null}
        >
            <Popup position={center}>
                <PopupComponent item={item} netadmin={netadmin} />
            </Popup>
        </Circle>
    );

    const renderMarker = (position, item, netadmin) => (
        <Marker position={position}
                icon={mapMarkers["homeownerAssoc"]}
                eventHandlers={{
                    contextmenu: (event) => handleRightClick(event, item)
                }}
        >
            <Popup position={position}><PopupComponent item={item}/></Popup>
        </Marker>
    );

    return (
        <LayerGroup>
            {netadminHomeownerAssocs && netadminHomeownerAssocs.length > 0 &&
                netadminHomeownerAssocs.filter(item => item.coordinates && item.coordinates.latitude && item.coordinates.longitude).map((item, index) => {
                        return (
                            <React.Fragment key={`${item.name}-${index}`}>
                                {clickedName === item.name && (
                                    renderCircle([item.coordinates.latitude, item.coordinates.longitude], 200, getCircleColor(item.apartmentCount), item, true)
                                )}
                                {renderCircle([item.coordinates.latitude, item.coordinates.longitude], 10, style.color, item, true)}
                                {renderMarker([item.coordinates.latitude, item.coordinates.longitude], item, true)}
                            </React.Fragment>
                        );
                    })}

            {homeownerAssocs && homeownerAssocs.length > 0 &&
                homeownerAssocs
                    .map((item, index) => {
                        return (
                            <React.Fragment key={`${item.properties.name}-${index}`}>
                                {clickedName === item.properties.name && (
                                    renderCircle(findMarkerCoordinate(item), 200, getCircleColor(item.properties.apartmentCount), item.properties, false)
                                )}
                                {item.geometry.coordinates[0].length > 1 ? (
                                    <GeoJSON
                                        data={item}
                                        style={style}
                                        item={item}
                                        eventHandlers={{
                                            contextmenu: (event) => handleRightClick(event, item.properties)
                                        }}
                                        ref={hoveredName === (item.properties ? item.properties.name : item.name) ? markerRef : null}
                                    >
                                        <Popup position={findMarkerCoordinate(item)}>
                                            <PopupComponent item={item.properties}/>
                                        </Popup>
                                    </GeoJSON>
                                ) : (
                                    renderCircle(findMarkerCoordinate(item), 10, style.color, item.properties, false)
                                )}
                                {renderMarker(findMarkerCoordinate(item), item.properties, false)}
                            </React.Fragment>
                        );
                    })}
        </LayerGroup>
    );
};

export default RenderPolygonsNetadmin;