import firstUpperCase from "../../../functions/formats-and-regex/FirstUpperCase";
import React, { useEffect, useState } from "react";
import GetAll from "../../../functions/apiCalls/Get/GetAll";
import apiUrls from "../../../configs/api";
import GetById from "../../../functions/apiCalls/Get/GetById";
import Update from "../../../functions/apiCalls/Update/Update";
import FindIfFullNode from "../../../functions/page-spesific/infoPage/FindIfFullNode";
import HandleNotification from "../../../functions/notifications/HandleNotification";

const AddNode = ({ addNodeMode, setAddNodeMode, homeownerAssocData, setHomeownerAssocData, nodes, id }) => {
    const [allNodes, setAllNodes] = useState([]);
    const [filteredNodes, setFilteredNodes] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [selectedNode, setSelectedNode] = useState(null);
    const [nodeDetails, setNodeDetails] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (addNodeMode) {
                const allNodes = await GetAll(apiUrls.get.nodes);
                setAllNodes(allNodes);
                setFilteredNodes(allNodes);
            }
        };
        fetchData();
    }, [addNodeMode]);

    const handleSearchInputChange = (e) => {
        const input = e.target.value.toLowerCase();
        setSearchInput(input);

        const filtered = allNodes.filter(
            (node) =>
                node.name.toLowerCase().includes(input) ||
                firstUpperCase(node.nodeType).toLowerCase().includes(input)
        );
        setFilteredNodes(filtered);
    };

    const handleNodeSelect = (node) => {
        setSelectedNode(node.id);
        setNodeDetails(node);
        setSearchInput(node.name);
        setShowDropdown(false);
    };

    const handleAddNode = async (e) => {
        e.preventDefault();

        try {
            let bodyData = {
                homeownerAssocId: id,
                nodeId: selectedNode,
            };
            const response = await Update(id, apiUrls.put.addNodeToHomeownerAssoc, bodyData);

            if (response) {
                const updatedHomeownerAssoc = await GetById(apiUrls.get.homeownerAssocs, id);
                await HandleNotification(
                    homeownerAssocData,
                    `Koblet borettslaget ${homeownerAssocData.properties.name} til noden`,
                    nodeDetails.name
                );
                if (updatedHomeownerAssoc) {
                    setHomeownerAssocData(updatedHomeownerAssoc);
                    setAddNodeMode(false);
                }
            } else {
                console.error("Failed to add node to homeowner association");
            }
        } catch (error) {
            console.error("Error adding node to homeowner association:", error);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Backspace") {
            setSelectedNode(null);
            setNodeDetails(null);
            setSearchInput("");
            setFilteredNodes(allNodes);
        }
    };

    return (
        <form onSubmit={handleAddNode} className="flex--row align-items--center">
            <div className="add-node--searchable-dropdown">
                {showDropdown && filteredNodes.length > 0 && (
                    <div className="dropdown-list">
                        {filteredNodes
                            .filter((node) => !nodes.some((homeownerNode) => homeownerNode.id === node.id))
                            .map((node) => (
                                <p
                                    key={node.id}
                                    className={`dropdown-item ${FindIfFullNode(node) ? "disabled" : ""}`}
                                    onMouseDown={() => !FindIfFullNode(node) && handleNodeSelect(node)} // Prevent losing focus on click
                                >
                                    {node.name} ({firstUpperCase(node.nodeType)}){" "}
                                    {FindIfFullNode(node) ? "(Fullt)" : ""}
                                </p>
                            ))}
                    </div>
                )}
                <input
                    type="text"
                    value={searchInput}
                    onChange={handleSearchInputChange}
                    onFocus={() => {
                        setShowDropdown(true);
                        setFilteredNodes(allNodes);
                    }}
                    onBlur={() => setShowDropdown(false)}
                    onKeyDown={handleKeyDown}
                    placeholder="Søk Node..."
                    className="search-input"
                />
            </div>

            <button
                type="submit"
                className={`btn btn-secondary-white-bg ${!selectedNode ? "disabled" : ""}`}
                disabled={!selectedNode}
            >
                Lagre
            </button>
        </form>
    );
};

export default AddNode;




