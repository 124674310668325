import GetAll from "../apiCalls/Get/GetAll";
import apiUrls from "../../configs/api";

const HomeownerAssocsNetadmin = async (setNetadminHomeownerAssocs, setDisabled) => {
    try {
        const netadminHomeownerAssocs = await GetAll(apiUrls.netamin.getHomeownerAssocs);
        if (netadminHomeownerAssocs) {
            const mapHomeownerAssocs = await GetAll(apiUrls.get.homeownerAssocs);
            if (mapHomeownerAssocs) {
                const filteredHomeownerassomaps = netadminHomeownerAssocs.filter(
                    assoc => !mapHomeownerAssocs.some(mapAssoc => mapAssoc.properties.name === assoc.name)
                );
                setNetadminHomeownerAssocs(filteredHomeownerassomaps);
            }
        }
        else {
            if (setDisabled !== null) {
                setDisabled(true);
            }
        }
    } catch (error) {
        console.error(error);
    }
}
export default HomeownerAssocsNetadmin;