import GetCoordinatesFromAddress from "../apiCalls/osm/GetCoordinatesFromAddress";

const FindCoordinatesFromAddress = async (address) => {
    try {
        if (!address) {
            throw new Error('Address is empty');
        }
        const response = await GetCoordinatesFromAddress(address);
        if (response && response.length > 0) {
            const location = response[0];
            return [parseFloat(location.lat), parseFloat(location.lon)];
        } else {
            throw new Error('No coordinates found for the address');
        }
    } catch (error) {
        console.error('Error fetching geocoding data:', error.message);
        throw error; // Re-throw the error so it can be handled in the calling function
    }
};

export default FindCoordinatesFromAddress;

