import React, {useState} from 'react';
import apiUrls from "../../../configs/api";
import InfoMessages from "../../../components/messagesAndPopups/InfoMessages";
import PhoneRegex from "../../../functions/formats-and-regex/PhoneRegex";
import EmailPhoneChecks from "../../../functions/inputs/EmailPhoneChecks";
import UpdateUser from "../../../functions/apiCalls/Update/UpdateUser";
import SmsCodeChangePhone from "../../../components/forms/SmsCodeChangePhone";
import PasswordRegex from "../../../functions/formats-and-regex/PasswordRegex";

const EditUserDisplay = ({user, setUserChanges}) => {
    const userId = sessionStorage.getItem('userId');
    const [formData, setFormData] = useState({
        username: user.username ? user.username : '',
        email: user.email ? user.email : '',
        phoneNumber: user.phoneNumber ? user.phoneNumber : '+47',
        currentPassword: '',
        newPassword: '',
        newPasswordRepeat: ''
    });
    const [messageData, setMessageData] = useState(null);
    const [changePhoneNumber, setChangePhoneNumber] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateInfoFields = () => {
        if (!formData.username || !formData.email || formData.phoneNumber.length !== 11) {
            setMessageData({ type: 'warning', message: 'Alle felt må fylles inn!' });
            return false;
        }
        if (formData.username === user.username && formData.email === user.email && formData.phoneNumber === user.phoneNumber) {
            setMessageData({ type: 'warning', message: 'Ingen endringer gjort på brukernavn, epost adresse og mobilnummer.' });
            return false;
        }
        return true;
    };

    const validatePasswordFields = () => {
        if (!formData.currentPassword || !formData.newPassword || !formData.newPasswordRepeat) {
            setMessageData({ type: 'warning', message: 'Alle felt må fylles inn!' });
            return false;
        }
        if (formData.currentPassword && (formData.newPassword !== formData.newPasswordRepeat)) {
            setMessageData({ type: 'warning', message: 'Nye passord er ikke like!' });
            return false;
        }
        if (!PasswordRegex(formData.newPassword)) {
            setMessageData({ type: 'warning', message: 'Passordet må være minst 8 tegn og inneholde store, små bokstaver og 1 tall!' });
            return false;
        }
        return true;
    };

    const handleInfoSubmit = async (e) => {
        e.preventDefault();
        if (!validateInfoFields()) return;
        const phoneNumber = PhoneRegex(formData.phoneNumber);
        if (!EmailPhoneChecks(formData, setMessageData, phoneNumber)) return;
        const data = {
            username: formData.username,
            email: formData.email,
            phoneNumber,
        };
        try {
            const response = await UpdateUser(userId, apiUrls.put.updateUserInfo, data);
            if (response.status === 200) {
                if (formData.phoneNumber !== user.phoneNumber) {
                    setChangePhoneNumber(true);
                    setMessageData(null);
                } else {
                    setUserChanges(Math.random());
                    setMessageData({ type: 'success', message: 'Bruker oppdatert.' });
                }
            } else {
                setMessageData({
                    type: response.status === 409 ? 'warning' : 'error',
                    message: response.status === 409 ? response.message : 'En feil oppsto. Prøv igjen senere'
                });
            }
        } catch {
            setMessageData({ type: 'error', message: 'En feil oppsto. Prøv igjen senere' });
        }
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        if (!validatePasswordFields()) return;
        const data = {
            oldPassword : formData.currentPassword,
            newPassword: formData.newPassword
        };
        try {
            const response = await UpdateUser(userId, apiUrls.put.updateUserPassword, data);
            if (response.status === 200) {
                setMessageData({ type: 'success', message: 'Passord endret.' });
                setFormData({ ...formData, currentPassword: '', newPassword: '', newPasswordRepeat: '' });
            } else {
                setMessageData({
                    type: response.status === 401 ? 'warning' : 'error',
                    message: response.status === 401 ? 'Feil passord oppgitt på gammelt passord.' : 'En feil oppsto. Prøv igjen senere'
                });
            }

        } catch {
            setMessageData({ type: 'error', message: 'En feil oppsto. Prøv igjen senere' });
        }
    };

    return (
        <div className="profile--display-page flex--column">
            <div className="flex--row align-items--center profile--display-header">
                <h2>Endre Bruker</h2>
                <div className="m-s-auto profile--display-header-message">
                    {messageData && (
                        <InfoMessages type={messageData.type} message={messageData.message}/>
                    )}
                </div>
            </div>
            {changePhoneNumber ? (
                <>
                    <p className="description">
                        En bekreftelseskode er sendt til {formData.phoneNumber}. Det kan ta noen sekunder før koden mottas.
                        Hvis ingenting skjer, sjekk at mobilnummeret er riktig.
                    </p>
                    <SmsCodeChangePhone
                        changePhoneNumber={changePhoneNumber}
                        setChangePhoneNumber={setChangePhoneNumber}
                        formData={formData}
                        userId={userId}
                        setMessageData={setMessageData}
                        type="update-phone"
                    />
                </>
            ) : (
                <>
                    <p className="description">
                        Kun de endringene du gjør vil bli lagret. Ved endring av mobilnummer vil en bekreftelseskode bli sendt
                        til det nye nummeret, så ha mobilen tilgjengelig.
                    </p>
                    <div className="flex--row profile--display-body justify-content-sb">
                        <form className="flex--column profile--edit-user-form "
                              onSubmit={handleInfoSubmit}>
                            <label className="flex--column">
                                Brukernavn
                            </label>
                            <input
                                type="text"
                                name="username"
                                autoComplete="new-username"
                                value={formData.username ? formData.username : ''}
                                onChange={handleChange}
                                placeholder={user.username}
                            />

                            <label className="flex--column">
                                Epost Adresse
                            </label>
                            <input
                                type="text"
                                name="email"
                                value={formData.email ? formData.email : ''}
                                onChange={handleChange}
                                placeholder="epost@obos.no"
                            />

                            <label className="flex--column">
                                Mobilnummer
                            </label>
                            <input
                                type="text"
                                name="phoneNumber"
                                value={formData.phoneNumber ? formData.phoneNumber : ''}
                                onChange={handleChange}
                                placeholder="+4799887766"
                            />
                            <button className="btn btn-secondary-white-bg m-s-auto" type="submit">
                                Lagre Endringer
                            </button>
                        </form>
                        <form className="flex--column profile--edit-user-form "
                              onSubmit={handlePasswordSubmit}>
                            <label className="flex--column">
                                Gammelt Passord
                            </label>
                            <input
                                type="password"
                                name="currentPassword"
                                value={formData.currentPassword}
                                onChange={handleChange}
                                autoComplete="off"
                                placeholder="MittGamlePassord123"
                            />

                            <label className="flex--column">
                                Nytt Passord
                            </label>
                            <input
                                type="password"
                                name="newPassword"
                                value={formData.newPassword}
                                onChange={handleChange}
                                placeholder="MittNyePassord123"
                            />

                            <label className="flex--column">
                                Gjenta Nytt Passord
                            </label>
                            <input
                                type="password"
                                name="newPasswordRepeat"
                                value={formData.newPasswordRepeat}
                                onChange={handleChange}
                                placeholder="MittNyePassordIgjen123"
                            />
                            <button className="btn btn-secondary-white-bg m-s-auto" type="submit">
                                Endre Passord
                            </button>
                        </form>
                    </div>
                </>
            )}
        </div>
    );
};
export default EditUserDisplay;