 const ChatSearchUsers = ({userSearchInput, setUserSearchInput}) => {
     const handleSearchChange = (e) => {
         setUserSearchInput(e.target.value);
     };

    return (
        <div className="chat--users-search flex--row align-items--center justify-content-sb">
            <input
                type="text"
                value={userSearchInput}
                onChange={handleSearchChange}
                placeholder="Brukernavn"
            />
            <svg className="icon--green-search"/>
        </div>
    );
 }
 export default ChatSearchUsers;