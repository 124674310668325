import apiUrls from "../../../configs/api";

const Login = async (bodyData, setToken, setMessageData, setUsername, setPassword,setSmsCodeMode) => {
    const response = await fetch(apiUrls.login.loginUser, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyData)
    });
    if (response.status === 200) {
        const data = await response.json();
        setToken(data);
    }
    else if (response.status === 204) {
        setSmsCodeMode(true);
    }
    else if (response.status === 401) {
        setMessageData({
            type: 'error',
            message: 'Feil brukernavn eller passord.'
        })
        setUsername('');
        setPassword('');
    }
    else {
        setMessageData({
            type: 'error',
            message: 'En feil oppsto. Prøv igjen.'
        })
        setUsername('');
        setPassword('');
        console.log(response);
    }
}
export default Login;