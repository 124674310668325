import React, { useState, useEffect } from 'react';
import InfoMessages from "../../messagesAndPopups/InfoMessages";
import NewConnectionInputs from './Inputs/ConnectionInputs';
import HandleInputChange from "../../../functions/inputs/HandleInputChange";

const NewKumForm = ({ onSubmit, isAddingKum, setIsAddingKum, pointCoordinate, setPointCoordinate }) => {
    const initialFormData = {
        name: '',
        address: '',
        floor: '',
        status: 'I bruk',
        latitude: '',
        longitude: '',
        kumType: '',
        spliceEnclosure: 'Ingen',
        notes: ''
    };
    const [formData, setFormData] = useState(initialFormData);
    const [messageData, setMessageData] = useState(null);

    useEffect(() => {
        if (isAddingKum && pointCoordinate.length === 2) {
            setFormData(prevData => ({ ...prevData, latitude: pointCoordinate[0], longitude: pointCoordinate[1] }));
        }
        if (!isAddingKum) {
            setFormData({
                name: '',
                address: '',
                floor: '',
                status: 'I bruk',
                latitude: '',
                longitude: '',
                kumType: 'EnkeltLokk (TK1)',
                spliceEnclosure: 'Ingen',
                notes: ''
            });
            setPointCoordinate('');
        }
    }, [pointCoordinate, isAddingKum, setPointCoordinate]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const { name, status, kumType, latitude, longitude, spliceEnclosure, notes } = formData;
        const lat = parseFloat(latitude);
        const lng = parseFloat(longitude);

        if (name && !isNaN(lat) && !isNaN(lng)) {
            onSubmit({
                name,
                status,
                kumType,
                notes,
                spliceEnclosure,
                coordinates: {
                    latitude: lat,
                    longitude: lng,
                },
            });
            setFormData(initialFormData);
            setPointCoordinate('');
        } else {
            setMessageData({
                type: 'warning',
                message: 'Du må fylle inn alle felt!'
            });
        }
    };

    const handleGetCoordinates = () => {
        setIsAddingKum(prev => !prev);
    };

    useEffect(() => {
        if (isAddingKum) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [isAddingKum]);

    return (
        <div className="map--add-form flex--column justify-content-center">
            <div className="map--add-form-header flex--row justify-content-sb">
                <h3>Tilkoblingspunkt</h3>
                <button
                    className={`btn btn-secondary-white-bg flex--row ${isAddingKum ? 'active' : ''}`}
                    onClick={handleGetCoordinates}
                >
                    {isAddingKum ? (
                        <>
                            Slett Punkt <svg className="icon--green-edit"/>
                        </>
                    ) : (
                        <>
                            Marker Punkt <svg className="icon--white-edit"/>
                        </>
                    )}
                </button>
                <div className="helping-box helping-box--coordinates">
                    {isAddingKum ? (
                        <p>Klikk for å slette markeringene gjort på kartet.</p>
                    ) : (
                        <p>Klikk for å hente koordinatene ved å klikke på kartet.</p>
                    )}
                </div>
            </div>

            <form onSubmit={handleSubmit} className="flex--column justify-content-sb align-items--center">
                <div className="map--add-form-message">
                    {messageData && (
                        <InfoMessages type={messageData.type} message={messageData.message}/>
                    )}
                </div>

                <NewConnectionInputs handleInputChange={HandleInputChange}
                                     formData={formData} setFromData={setFormData}/>
                <button
                    className="btn btn-secondary-white-bg"
                    type="submit"
                >
                    Opprett
                </button>
            </form>
        </div>
    );
};

export default NewKumForm;

