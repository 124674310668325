import React, {useEffect, useRef, useState} from "react";
import { LayerGroup, GeoJSON, Marker, CircleMarker, Popup, Polygon } from "react-leaflet";
import DrawPolygons from "./DrawPolys";
import icons from "../../iconsAndStyles/Icons";
import findMarkerCoordinate from "../../../functions/map/FindMarkerCoordinate";
import AllRenderFilter from "../../../functions/filter/filterRenders/AllRenderFilter";
import PolyRenderFilter from "../../../functions/filter/filterRenders/PolyRenderFilter";

const RenderPolygons = ({ data, style, radius, mapIcons, PopupComponent, handleUpdateCoordinates, isUpdatingPolygon, updatingId, getUpdatedCoordinates, snapMarker, drawingIcon, hoveredResult, layerFilters, isAddingPoint, isAddingLine, isAddingPoly, isUpdatingPoint }) => {
    const [draggedCoordinates, setDraggedCoordinates] = useState([]);
    const markerRef = useRef(null);
    const hoveredId = hoveredResult && (hoveredResult.homeownerAssosiationId || hoveredResult.id);

    useEffect(() => {
        if (isUpdatingPolygon) {
            const updatedCoordinates = data.find((item) => item.id === updatingId)?.geometry.coordinates[0].map(coord => [coord[1], coord[0]]) || [];
            setDraggedCoordinates(updatedCoordinates);
            getUpdatedCoordinates(updatedCoordinates);
        }
    }, [updatingId, data, isUpdatingPolygon]);

    useEffect(() => {
        if (hoveredResult && hoveredResult.type === "Adresse") {
            if (hoveredResult.homeownerAssosiationId && markerRef.current) {
                const marker = markerRef.current;
                marker.fire('click');

                setTimeout(() => {
                    marker.fire('click');
                }, 500);
            }
        } else if (hoveredResult && hoveredResult.id && markerRef.current) {
            const marker = markerRef.current;
            marker.fire('click');

            setTimeout(() => {
                marker.fire('click');
            }, 300);
        }

    }, [hoveredResult]);

    const handleDrawCoordinates = (drawnCoordinates) => {
        setDraggedCoordinates(drawnCoordinates);
        getUpdatedCoordinates(drawnCoordinates);
    }

    const handleMarkerDragEnd = (index, event) => {
        const { target } = event;
        if (target) {
            const { _latlng } = target;
            if (_latlng) {
                const { lat, lng } = _latlng;
                const newCoordinates = [...draggedCoordinates];

                if (target.options.isMidMarker) {
                    const nextIndex = index;
                    const newCoordinate = [lat, lng];
                    newCoordinates.splice(nextIndex, 0, newCoordinate);
                } else {
                    newCoordinates[index] = [lat, lng];
                }

                setDraggedCoordinates(newCoordinates);
                getUpdatedCoordinates(newCoordinates);
            }
        }
    };

    const markerEventHandlers = (index) => ({
        dragend: (event) => {
            handleMarkerDragEnd(index, event);
        }
    });

    const filteredData = data.filter((item) => {
        const allRenderResult = AllRenderFilter(layerFilters, item);
        const polyRenderResult = PolyRenderFilter(layerFilters, item);

        return allRenderResult && polyRenderResult;
    });

    return (
        <LayerGroup>
            {filteredData.map((item, index) => (
                <React.Fragment key={item.id}>
                    {item.geometry.coordinates[0].length > 1 ? (
                        isUpdatingPolygon && updatingId === item.id ? (
                            <>
                                <Polygon
                                    key={`polygon-${item.id}`}
                                    positions={draggedCoordinates}
                                    pathOptions={style}
                                />
                                {draggedCoordinates.map((coord, index) => (
                                    <Marker
                                        key={`coordinate-${index}`}
                                        position={coord}
                                        draggable={true}
                                        eventHandlers={markerEventHandlers(index)}
                                        icon={icons["eachCoordinate"]}
                                    />
                                ))}
                                {draggedCoordinates.slice(1).map((coord, index) => (
                                    <Marker
                                        key={`mid-${index}`}
                                        position={[
                                            (coord[0] + draggedCoordinates[index][0]) / 2,
                                            (coord[1] + draggedCoordinates[index][1]) / 2
                                        ]}
                                        draggable={true}
                                        eventHandlers={markerEventHandlers(index + 1)}
                                        icon={icons["eachCoordinate"]}
                                        isMidMarker={true}
                                    />
                                ))}
                            </>
                        ) : (
                            <>
                                <GeoJSON
                                    data={item}
                                    style={style}
                                    item={item}
                                    ref={hoveredId === item.id ? markerRef : null}
                                >
                                    {!(isAddingLine || isAddingPoint || isAddingPoly || isUpdatingPoint ) && (
                                        <Popup position={findMarkerCoordinate(item)}>
                                            <PopupComponent item={item} handleUpdateCoordinates={handleUpdateCoordinates} />
                                        </Popup>
                                    )}
                                </GeoJSON>

                                {layerFilters.all.icons && layerFilters.homeownerAssocs.icons && (
                                    <Marker position={findMarkerCoordinate(item)} icon={item.properties.netadminData ? mapIcons.netadminIcon : mapIcons.defaultIcon}>
                                        {!(isAddingLine || isAddingPoint || isAddingPoly || isUpdatingPoint) && (
                                            <Popup position={findMarkerCoordinate(item)}>
                                                <PopupComponent item={item} handleUpdateCoordinates={handleUpdateCoordinates} />
                                            </Popup>
                                        )}
                                    </Marker>
                                )}
                            </>
                        )
                    ) : item.geometry.coordinates[0].length === 1 ? (
                        isUpdatingPolygon && updatingId === item.id ? (
                            <DrawPolygons
                                key={index}
                                color={style.color}
                                firstCoordinateIcon={item.properties.netadminData ? mapIcons.netadminIcon : mapIcons.defaultIcon}
                                isAdding={isUpdatingPolygon && updatingId === item.id}
                                drawingIcon={drawingIcon}
                                snapMarker={snapMarker}
                                isUpdating={true}
                                handleDrawCoordinates={handleDrawCoordinates}
                            />
                        ) : (
                            <CircleMarker
                                key={`circle-${item.id}`}
                                center={findMarkerCoordinate(item)}
                                radius={radius}
                                color={style.color}
                                fillColor={style.fillColor}
                                fillOpacity={style.fillOpacity}
                                weight={style.weight}
                                ref={hoveredId === item.id ? markerRef : null}
                            >
                                {!(isAddingLine || isAddingPoint || isAddingPoly) && (
                                    <Popup position={findMarkerCoordinate(item)}>
                                        <PopupComponent item={item} handleUpdateCoordinates={handleUpdateCoordinates} />
                                    </Popup>
                                )}
                            </CircleMarker>
                        )
                    ) : null}
                </React.Fragment>
            ))}

            {filteredData.map((item, index) => (
                updatingId !== item.id && layerFilters.all.icons && layerFilters.homeownerAssocs.icons ? (
                    <Marker
                        key={`marker-${item.id}`}
                        position={findMarkerCoordinate(item)}
                        icon={item.properties.netadminData ? mapIcons.netadminIcon : mapIcons.defaultIcon}
                        item={item}
                    >
                        {!(isAddingLine || isAddingPoint || isAddingPoly) && (
                            <Popup position={findMarkerCoordinate(item)}>
                                <PopupComponent item={item} handleUpdateCoordinates={handleUpdateCoordinates} />
                            </Popup>
                        )}
                    </Marker>
                ) : null
            ))}
        </LayerGroup>
    );
};

export default RenderPolygons;




