import React, { useEffect, useState } from "react";
import apiUrls from "../../configs/api";
import DeletePopup from "../messagesAndPopups/DeletePopup";
import GetUserType from "../../functions/apiCalls/Get/GetUserType";

const ListContainer = ({ id, icon, isOpen, toggleBox, items, header, setData, headers, paragraphs, showDeletePopup, setShowDeletePopup, connectionData, setRefresh, handleDelete, deleteItem }) => {
    const userId = sessionStorage.getItem('userId');
    const [userType, setUserType] = useState(null);
    const [apiUrl, setApiUrl] = useState('');

    useEffect(() => {
        const fetchUserType = async () => {
            const fetchedUserType = await GetUserType();
            setUserType(fetchedUserType);
        };
        fetchUserType();
    }, []);

    const determineEndpoint = (item) => {
        if (item && (item.properties?.type || item.type)) {
            switch (item.properties?.type || item.type) {
                case 'Kum': return "kum";
                case 'Node': return "node";
                case 'Adresse': return "adresse";
                case 'Kabel': return "kabel";
                case 'Rør': return "ror";
                case 'Borettslag': return "borettslag";
                default: return '';
            }
        }
        return '';
    };

    const determineApiUrl = (item) => {
        const itemType =  item.properties ? item.properties.type : item.type;
        let returnUrl = "";
        if (itemType) {
            switch (itemType) {
                case 'Kum':
                    returnUrl = apiUrls.delete.deleteKum;
                    break;
                case 'Node':
                    returnUrl = apiUrls.delete.deleteNode;
                    break;
                case 'Kabel':
                    returnUrl = apiUrls.delete.deleteCable;
                    break;
                case 'Rør':
                    returnUrl = apiUrls.delete.deletePipe;
                    break;
                case 'Borettslag':
                    returnUrl = apiUrls.delete.deleteHomeownerAssoc;
                    break;
                default:
                    console.log("Item type not matched, returning empty URL");
                    break;
            }
        } else {
            console.log("No valid item type found");
        }
        return returnUrl;
    };


    const buttonLock = (item) => {
        const editLock = item.properties ? item.properties.editLock : item.editLock;
        const editLockUser = item.properties ? item.properties.editorLockUserId : item.editorLockUserId;
        return editLock && (editLockUser !== userId && userType !== "Admin" && userType !== "Utvikler");
    };

    const handleDeleteClick = (item) => {
        handleDelete(item);
        setApiUrl(determineApiUrl(item));
        setShowDeletePopup(true);
    };

    return (
        <div id={id} className={`list--closeable ${isOpen[id] ? 'open' : ''} br flex--column`}>
            <div className="list--closeable-header flex--row align-items--center justify-content-sb" onClick={() => toggleBox(id)}>
                <h3 className="d-flex align-items--center">
                    <svg className={`icon--${icon}`} />
                    {header}
                </h3>
                <svg className="icon--black-chevron"/>
            </div>
            <div className="list--closeable-container flex--row justify-content-sb">
                {items && items.length > 0 ? (
                    <div className="flex--column">
                        <div className={`flex--row list--closeable-table-header ${userType !== "Rådgiver Read Only" ? '' : 'wide'}`}>
                            {headers && headers.map((header, index) => (
                                <p key={index} className={`w-${header.width}`}>
                                    <b>{header.label}</b>
                                </p>
                            ))}
                        </div>
                        <div className="list--closeable-table-data flex--column justify-content-sb">
                            {items.map((item, rowIndex) => (
                                <div key={rowIndex} className="flex--row">
                                    <div className={`flex--row list--closeable-table-data-row ${userType !== "Rådgiver Read Only" ? '' : 'wide'}`}>
                                        {paragraphs && paragraphs.map((paragraph, colIndex) => (
                                            <p key={`${id}-p-${colIndex}`} className={`w-${headers && headers[colIndex] ? headers[colIndex].width : 'default'}`}>
                                                {item && paragraph(item)}
                                            </p>
                                        ))}
                                    </div>
                                    {userType !== 'Rådgiver Read Only' && (
                                        <div className={`flex--row align-items--center list--closeable-table-buttons ${userType !== "Read Only" ? "justify-content-sb" : "justify-content-right"}`}>
                                            <button
                                                className="btn btn-primary-white-bg"
                                                onClick={() => window.location.href = `/${determineEndpoint(item)}/${item.id}`}>
                                                Se mer
                                            </button>
                                            {userType !== 'Read Only' && (
                                                <button
                                                    className={`btn btn-delete-white-bg ${buttonLock(item) ? 'disabled' : ''}`}
                                                    onClick={() => handleDeleteClick(item)}
                                                    disabled={buttonLock(item)}
                                                >
                                                    Slett
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="list--closeable-container flex--row justify-content-sb">
                        <p>Ingen lagt til ennå!</p>
                    </div>
                )}
            </div>

            {showDeletePopup && apiUrl && (
                <DeletePopup
                    item={deleteItem}
                    connectionData={connectionData}
                    setShowDeletePopup={setShowDeletePopup}
                    apiUrlDelete={apiUrl}
                    redirect={false}
                    setData={setData}
                    setRefresh={setRefresh}
                />
            )}
        </div>
    );
}

export default ListContainer;




