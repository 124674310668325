const HandleSmsInput = (e, setSmsCode) => {
    const value = e.key;
    if (/\d/.test(value)) {
        setSmsCode((prev) => {
            const updatedCode = { ...prev };
            if (!prev.num1) updatedCode.num1 = value;
            else if (!prev.num2) updatedCode.num2 = value;
            else if (!prev.num3) updatedCode.num3 = value;
            else if (!prev.num4) updatedCode.num4 = value;
            return updatedCode;
        });
    } else if (value === "Backspace" || value === "Delete") {
        setSmsCode((prev) => {
            const updatedCode = { ...prev };
            if (prev.num4) updatedCode.num4 = "";
            else if (prev.num3) updatedCode.num3 = "";
            else if (prev.num2) updatedCode.num2 = "";
            else if (prev.num1) updatedCode.num1 = "";
            return updatedCode;
        });
    }
}
export default HandleSmsInput;