import HeaderLoggedIn from "../../components/headers/headerLoggedIn";
import apiUrls from "../../configs/api";
import React, {useEffect, useState} from "react";
import MiniMap from "../../components/maps/MiniMap";
import GetAll from "../../functions/apiCalls/Get/GetAll";
import ListContainer from "../../components/containers/ListContainer";
import getAvailablePorts from "../../functions/extractors/getAvailablePorts";
import getAllPorts from "../../functions/extractors/getAllPorts";

const ListPage = () => {
    const [kummer, setKummerData] = useState(null);
    const [nodes, setNodesData] = useState(null);
    const [cables, setCablesData] = useState(null);
    const [pipes, setPipesData] = useState(null);
    const [homeownerAssocs, setHomeownerAssocsData] = useState(null);
    const [refresh, setRefresh] = useState(null);
    const [isOpen, setIsOpen] = useState({
        kum: false,
        node: false,
        cable: false,
        pipe: false,
        homeownerAssoc: false
    });

    const [totalObjects, setTotalObjects] = useState(0);
    const [inUseCount, setInUseCount] = useState(0);
    const [underConstruction, setUnderConstruction] = useState(0);
    const [tempOutOfUse, setTempOutOfUse] = useState(0);
    const [available, setAvailable] = useState(0);
    const [damaged, setDamaged] = useState(0);
    const [finished, setFinished] = useState(0);
    const [planning, setPlanning] = useState(0);
    const [drawingOnly, setDrawingOnly] = useState(0);

    const [deleteItem, setDeleteItem] = useState(null);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [connectionData, setConnectionData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const kummerData = await GetAll(apiUrls.get.kummer);
            const nodesData = await GetAll(apiUrls.get.nodes);
            const cablesData = await GetAll(apiUrls.get.cables);
            const pipesData = await GetAll(apiUrls.get.pipes);
            const homeownerAssocData = await GetAll(apiUrls.get.homeownerAssocs);
            setKummerData(kummerData);
            setNodesData(nodesData);
            setCablesData(cablesData);
            setPipesData(pipesData);
            setHomeownerAssocsData(homeownerAssocData);
        };
        fetchData();
    }, [refresh]);

    useEffect(() => {
        if (kummer && nodes && cables && pipes && homeownerAssocs) {
            const totalCount = kummer.length + nodes.length + cables.length + pipes.length + homeownerAssocs.length;
            setTotalObjects(totalCount);

            const itemsToCheck = [
                { list: kummer, getStatus: item => item.status !== undefined },
                { list: nodes, getStatus: item => item.status !== undefined },
                { list: cables, getStatus: item => item.properties?.status !== undefined },
                { list: pipes, getStatus: item => item.properties?.status !== undefined },
                { list: homeownerAssocs, getStatus: item => item.properties?.status !== undefined },
            ];

            let inUseCount = 0;
            let underConstructionCount = 0;
            let tempOutOfUseCount = 0;
            let availableCount = 0;
            let damagedCount = 0;
            let finishedCount = 0;
            let planningCount = 0;
            let drawingOnlyCount = 0;

            itemsToCheck.forEach(({ list, getStatus }) => {
                list.forEach(item => {
                    if (getStatus(item)) {
                        switch (item.properties ? item.properties.status : item.status) {
                            case "I bruk":
                            case "I drift":
                                inUseCount++;
                                break;
                            case "Bygges":
                                underConstructionCount++;
                                break;
                            case "Midlertidig stengt":
                            case "Under vedlikehold":
                                tempOutOfUseCount++;
                                break;
                            case "Skadet":
                                damagedCount++;
                                break;
                            case "Ledig":
                                availableCount++;
                                break;
                            case "Utført":
                            case "Ferdig stilt":
                                finishedCount++;
                                break;
                            case "Under planlegging":
                                planningCount++;
                                break;
                            case "Kladd":
                                drawingOnlyCount++;
                                break;
                            default:
                                break;
                        }
                    }
                });
            });
            setInUseCount(inUseCount);
            setUnderConstruction(underConstructionCount);
            setTempOutOfUse(tempOutOfUseCount);
            setAvailable(availableCount);
            setFinished(finishedCount);
            setDamaged(damagedCount);
            setPlanning(planningCount);
            setDrawingOnly(drawingOnlyCount);
        }
    }, [kummer, nodes, cables, pipes, homeownerAssocs]);

    const toggleBox = (boxId) => {
        setIsOpen(prevState => ({
            ...prevState,
            [boxId]: !prevState[boxId]
        }));
    };
    const toggleAllBoxes = () => {
        const allBoxesOpen = Object.values(isOpen).every(value => value);
        const newState = {};
        for (const key in isOpen) {
            newState[key] = !allBoxesOpen;
        }
        setIsOpen(newState);
    };

    const handleDelete = async (item) => {
        if (item.properties?.type === 'Rør') {
            const pipes = await GetAll(apiUrls.get.pipes);
            const cables = await GetAll(apiUrls.get.cables);
            const combinedData = [...pipes, ...cables];
            setConnectionData(combinedData);
        }
        setDeleteItem(item);
        setShowDeletePopup(true);
    };

    const getName = (id, list, message) => {
        if (!id) {
            return message;
        }
        if (!Array.isArray(id)) {
            const matchingObject = list.find((object) => object.id === id);
            return matchingObject ? (matchingObject.properties ? matchingObject.properties.name : matchingObject.name) : message;
        }
        const names = id.map((itemId) => {
            const matchingObject = list.find((object) => object.id === itemId);
            return matchingObject ? (matchingObject.properties ? matchingObject.properties.name : matchingObject.name) : null;
        }).filter(Boolean);
        return names.length > 0 ? names.join(", ") : message;
    };

    useEffect(() => {
        document.body.classList.add("overflow-hidden");
        return () => {
            document.body.classList.remove("overflow-hidden");
        };
    }, []);

    return (
        <div>
            <HeaderLoggedIn/>
            <div className="list flex--row scroll">
                <div className="list--map">
                    <MiniMap zoom={4} coordinates={[59.909116854318796, 10.77090665108958]}/>
                </div>
                <div className="list--content-container">
                    <div className="list--content">
                        <div className="list--header flex--row justify-content-sb align-items--center">
                            <h1>Alle Objekter</h1>
                            <div className="flex--row">
                                <button
                                    className={`btn btn-primary-white-bg ${Object.values(isOpen).every(value => value) ? 'active' : ''}  flex--row justify-content-center align-items--center m-10px`}
                                    onClick={() => toggleAllBoxes()}
                                >
                                    {Object.values(isOpen).every(value => value) ? 'Lukk Alle' : 'Åpne Alle'}
                                </button>
                                <button
                                    className="btn btn-primary-white-bg flex--row justify-content-center align-items--center m-10px"
                                    onClick={() => setRefresh(Math.random())}>
                                    Last på nytt
                                    <svg className="icon--loading-white"/>
                                </button>
                            </div>
                        </div>
                        <div className="list--statistics flex--row justify-content-sb br">
                            <p>Totale objekter: <b>{totalObjects}</b></p>
                            <p>I bruk: <b>{inUseCount}</b></p>
                            <p>Utført/Ferdig stilt: <b>{finished}</b></p>
                            <p>Bygges: <b>{underConstruction}</b></p>
                            <p>Under planlegging: <b>{planning}</b></p>
                            <p>Skadet: <b>{damaged}</b></p>
                            <p>Midlertidlig ikke i bruk: <b>{tempOutOfUse}</b></p>
                            <p>Kladd: <b>{drawingOnly}</b></p>
                        </div>

                        <ListContainer
                            id="kum"
                            icon="kum"
                            isOpen={isOpen}
                            toggleBox={toggleBox}
                            items={kummer}
                            header="Tilkoblingspunkter"
                            setData={setKummerData}
                            headers={[
                                {label: 'Navn', width: 200},
                                {label: 'Status', width: 150},
                                {label: 'Type', width: 200},
                                {label: 'Skjøte', width: 75},
                                {label: 'Kabler', width: 75},
                                {label: 'Rør', width: 75}
                            ]}
                            paragraphs={[
                                (item) => item.name,
                                (item) => item.status,
                                (item) => item.kumType,
                                (item) => item.spliceEnclosure,
                                (item) => item.cables ? item.cables.length : 0,
                                (item) => item.pipes ? item.pipes.length : 0
                            ]}
                            showDeletePopup={showDeletePopup}
                            setShowDeletePopup={setShowDeletePopup}
                            connectionData={connectionData}
                            setRefresh={setRefresh}
                            handleDelete={handleDelete}
                            deleteItem={deleteItem}
                        />
                        <ListContainer
                            id="node"
                            icon="nodes"
                            isOpen={isOpen}
                            toggleBox={toggleBox}
                            items={nodes}
                            header="Noder"
                            setData={setNodesData}
                            headers={[
                                {label: 'Navn', width: 200},
                                {label: 'Status', width: 150},
                                {label: 'Type', width: 75},
                                {label: 'Switcher', width: 100},
                                {label: 'Porter', width: 75},
                                {label: 'Ledige Porter', width: 125},
                            ]}
                            paragraphs={[
                                (item) => item.name,
                                (item) => item.status,
                                (item) => item.nodeType,
                                (item) => item.switches ? item.switches.length : 0,
                                (item) => getAllPorts(item),
                                (item) => getAvailablePorts(item),
                            ]}
                            showDeletePopup={showDeletePopup}
                            setShowDeletePopup={setShowDeletePopup}
                            connectionData={connectionData}
                            setRefresh={setRefresh}
                            handleDelete={handleDelete}
                            deleteItem={deleteItem}
                        />
                        <ListContainer
                            id="pipe"
                            icon="pipe"
                            isOpen={isOpen}
                            toggleBox={toggleBox}
                            items={pipes}
                            header="Rør"
                            setData={setPipesData}
                            headers={[
                                {label: 'Navn', width: 200},
                                {label: 'Status', width: 150},
                                {label: 'I Rør', width: 60},
                                {label: 'Farge', width: 75},
                                {label: 'Størrelse', width: 100},
                                {label: 'Fullt', width: 60},
                                {label: 'Kabler i', width: 75},
                                {label: 'Rør i', width: 75},
                            ]}
                            paragraphs={[
                                (item) => item.properties.name,
                                (item) => item.properties.status,
                                (item) => item.properties.pipeInPipe ? getName(item.properties.pipeInPipeId, pipes, "Nei2") : 'Nei',
                                (item) => item.properties.color,
                                (item) => item.properties.size,
                                (item) => item.properties.filled ? 'Ja' : 'Nei',
                                (item) => item.properties.cables ? item.properties.cables.length : 0,
                                (item) => item.properties.pipes ? item.properties.pipes.length : 0
                            ]}
                            showDeletePopup={showDeletePopup}
                            setShowDeletePopup={setShowDeletePopup}
                            connectionData={connectionData}
                            setRefresh={setRefresh}
                            handleDelete={handleDelete}
                            deleteItem={deleteItem}
                        />
                        <ListContainer
                            id="cable"
                            icon="cable"
                            isOpen={isOpen}
                            toggleBox={toggleBox}
                            items={cables}
                            header="Kabler"
                            setData={setCablesData}
                            headers={[
                                {label: 'Navn', width: 200},
                                {label: 'Status', width: 150},
                                {label: 'Type', width: 75},
                                {label: 'I Rør', width: 75},
                                {label: 'Noder', width: 150},
                                {label: 'Tilkoblingspunkter', width: 150}
                            ]}
                            paragraphs={[
                                (item) => item.properties.name,
                                (item) => item.properties.status,
                                (item) => item.properties.cableType,
                                (item) => pipes && getName(item.properties.pipeId, pipes, "Nei"),
                                (item) =>
                                    item && item.properties.nodes && item.properties.nodes.length > 0 ? (
                                        getName(item.properties.nodes.map(node => node.pointId), nodes, "Ingen tilkoblet")
                                    ) : (
                                        "Ingen tilkoblet"
                                    ),
                                (item) =>
                                    item && item.properties.kummer && item.properties.kummer.length > 0 ? (
                                        getName(item.properties.kummer.map(kum => kum.pointId), kummer, "Ingen tilkoblet")
                                    ) : (
                                        "Ingen tilkoblet"
                                    )
                            ]}
                            showDeletePopup={showDeletePopup}
                            setShowDeletePopup={setShowDeletePopup}
                            connectionData={connectionData}
                            setRefresh={setRefresh}
                            handleDelete={handleDelete}
                            deleteItem={deleteItem}
                        />
                        <ListContainer
                            id="homeownerAssoc"
                            icon="homeownerassoc"
                            isOpen={isOpen}
                            toggleBox={toggleBox}
                            items={homeownerAssocs}
                            header="Borettslag"
                            setData={setHomeownerAssocsData}
                            headers={[
                                {label: 'Navn', width: 200},
                                {label: 'Status', width: 150},
                                {label: 'Leiligheter', width: 150},
                                {label: 'Oppkoblede', width: 150},
                                {label: 'Noder', width: 150},
                            ]}
                            paragraphs={[
                                (item) => item.properties.name,
                                (item) => item.properties.status,
                                (item) => item && item.properties.apartmentCount ? item.properties.apartmentCount : 0,
                                (item) => item && item.properties.portConnections ? item.properties.portConnections : 0,
                                (item) =>
                                    item && item.properties.nodes && item.properties.nodes.length > 0 ? (
                                        getName(item.properties.nodes.map(node => node.pointId), nodes, "Ingen noder tilkoblet")
                                    ) : (
                                        "Ingen noder tilkoblet"
                                    )
                            ]}
                            showDeletePopup={showDeletePopup}
                            setShowDeletePopup={setShowDeletePopup}
                            connectionData={connectionData}
                            setRefresh={setRefresh}
                            handleDelete={handleDelete}
                            deleteItem={deleteItem}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ListPage;