import React, {useEffect, useState} from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import IsDecember from "../../functions/checks/IsDecember";

const ProfileMap = () => {
    const [isDecember, setIsDecember] = useState(false);

    useEffect(() => {
        if (IsDecember()) {
            setIsDecember(true);
        }
    }, []);

    return (
        <div className="profile--map-container">
            <MapContainer
                center={[62.963776863118085, 8.557929841897224]}
                zoom={2}
                className="profile--map"
                scrollWheelZoom={false}
                doubleClickZoom={false}
                dragging={false}
                zoomControl={false}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
            </MapContainer>

            {isDecember && (
                <div className="snow-overlay"></div>
            )}
        </div>
    );
};

export default ProfileMap;

