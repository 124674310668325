import { useEffect, useState } from "react";
import FormatChatMessage from "../../../functions/formats-and-regex/FormatChatMessage";
import Post from "../../../functions/apiCalls/Post/Post";
import apiUrls from "../../../configs/api";

const ChatAllUserChats = ({ usersSessions, setUsersSessions, setChosenUserId, users, chosenSessionId, setChosenSessionId, seeUnreadOnly, userSearchInput, messages }) => {
    const userId = sessionStorage.getItem("userId");
    const [allChatsList, setAllChatsList] = useState([]);

    const updateSessions = (updatedUsersSessions) => {
        const updatedChats = users
            .filter(user => user.id !== userId)
            .map(user => {
                const session = updatedUsersSessions.find(session => session.messagerId === user.id);
                if (!session) return null;
                const lastMessage = session.lastMessage || "Dere har ikke startet en chat";

                return {
                    chatSessionId: session.chatSessionId,
                    username: session.messagerUsername,
                    lastMessage: lastMessage,
                    lastMessageDate: session.lastMessageDate,
                    lastMessageRead: session.lastMessageRead,
                    messagerId: session.messagerId
                };
            })
            .filter(chat => chat)
            .filter(chat => {
                const matchesSearch = userSearchInput
                    ? chat.username.toLowerCase().includes(userSearchInput.toLowerCase())
                    : true;

                return (!seeUnreadOnly || !chat.lastMessageRead) && matchesSearch;
            });
        setAllChatsList(updatedChats);
    };

    useEffect(() => {
        const createMissingSessions = async () => {
            let newUsersSessions = [...usersSessions];
            let updated = false;

            for (let user of users) {
                if (user.id !== userId) {
                    const sessionExists = newUsersSessions.find(session => session.messagerId === user.id);

                    if (!sessionExists) {
                        try {
                            const data = { receiverId: user.id };
                            const response = await Post(apiUrls.chat.createSession, data);

                            if (response.ok) {
                                const responseData = await response.json();
                                newUsersSessions.push({
                                    chatSessionId: responseData.sessionId,
                                    messagerId: user.id,
                                    messagerUsername: user.username,
                                    lastMessage: responseData.lastMessage || "Dere har ikke startet en chat",
                                    lastMessageDate: new Date(),
                                    lastMessageRead: true
                                });
                                updated = true;
                            } else {
                                console.error("Failed to create session:", response.statusText);
                            }
                        } catch (error) {
                            console.error("Error creating session:", error);
                        }
                    }
                }
            }
            if (updated) {
                setUsersSessions(newUsersSessions);
            }
            updateSessions(newUsersSessions);
        };
        createMissingSessions();

    }, [users, userId, usersSessions, messages]);

    const handleChosenChat = (sessionId, userId) => {
        setChosenSessionId(sessionId);
        setChosenUserId(userId);
    };

    const hourOrDaysSinceSent = (dateSent) => {
        const now = new Date();
        const sentDate = new Date(dateSent);

        const diffMs = now - sentDate;
        const diffMinutes = Math.floor(diffMs / 1000 / 60);
        const diffHours = Math.floor(diffMinutes / 60);
        const diffDays = Math.floor(diffHours / 24);
        const diffWeeks = Math.floor(diffDays / 7);

        if (diffMinutes < 60) {
            return `${diffMinutes}m`;
        } else if (diffHours < 24) {
            return `${diffHours}t`;
        } else if (diffDays < 7) {
            return `${diffDays}d`;
        } else {
            return `${diffWeeks}u`;
        }
    };

    return (
        <div className="chat--all-sessions flex--column align-items--center">
            {allChatsList && allChatsList.length > 0 ? (
                allChatsList
                    .sort((a, b) => {
                        const isAValid = a.lastMessage && a.lastMessage !== "Dere har ikke startet en chat";
                        const isBValid = b.lastMessage && b.lastMessage !== "Dere har ikke startet en chat";
                        if (isAValid && !isBValid) return -1;
                        if (!isAValid && isBValid) return 1;
                        const dateA = new Date(a.lastMessageDate || 0);
                        const dateB = new Date(b.lastMessageDate || 0);
                        return dateB - dateA;
                    })
                    .map((chat, index) => (
                        <div
                            key={chat.id || `${chat.messagerId}-${index}`}
                            className={`chat--session flex--row justify-content-sb ${chosenSessionId === chat.chatSessionId ? 'chosen' : ''}`}
                            onClick={() => handleChosenChat(chat.chatSessionId, chat.messagerId)}
                        >
                            <div className="chat--session-info flex--column">
                                <h4>{chat.username}</h4>
                                <p>{FormatChatMessage(chat.lastMessage, "display")}</p>
                            </div>

                            {chat.lastMessage !== "Dere har ikke startet en chat" && (
                                <div className="chat--session-icons flex--row align-items--center">
                                    {!chat.lastMessageRead && <svg className="icon--new-message"/>}
                                    <p className="chat--hour-since flex--row align-items--center justify-content-center">
                                        {hourOrDaysSinceSent(chat.lastMessageDate)}
                                    </p>
                                </div>
                            )}
                        </div>
                    ))
            ) : (
                <p className="chat--no-matches flex--row align-items--center justify-content-center">
                    Ingen brukere funnet som matcher søket ditt
                </p>
            )}
        </div>
    );
};
export default ChatAllUserChats;

