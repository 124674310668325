const getUrlType = (type) => {
    switch (type) {
        case "Minikum":
        case "EnkeltLokk (TK1)":
        case "Dobbeltlokk (TK2)":
        case "TK3":
        case "Rundkum":
        case "Veggskap (Innvendig)":
        case "Veggskap (Utvendig)":
            return "kum";
        case "Node":
        case "Dist":
            return "node";
        case "Rør":
            return "ror";
        case "Kabel":
            return "kabel";
        case "Borettslag":
            return "borettslag";
        default:
            return "";
    }
}

const GoToInfoPage = (type, id) => {
    const urlType = getUrlType(type);
    window.location.href = `/${urlType}/${id}`;
}

export default GoToInfoPage;