import React, { useState } from 'react';
import SeeAllUsers from "./admin/SeeAllUsers";
import NewUserForm from "./admin/NewUserForm";
import EditUsersForm from "./admin/EditUsersForm";
import HandleDataDisplay from "./admin/HandleDataDisplay";

const AdminDisplay = () => {
    const [activeForm, setActiveForm] = useState('allUsers');

    const toggleForm = (form) => {
        setActiveForm(prevForm => (prevForm === form ? 'allUsers' : form));
    };

    return (
        <div className="profile--display-page flex--column">
            <div
                className="flex--row align-items--center justify-content-sb profile--display-header profile--admin-nav">
                <button
                    className={`btn btn-secondary-white-bg ${activeForm === 'allUsers' ? 'active' : ''}`}
                    onClick={() => toggleForm('allUsers')}
                >
                    Se Alle Brukere
                </button>
                <button
                    className={`btn btn-secondary-white-bg ${activeForm === 'addNewUser' ? 'active' : ''}`}
                    onClick={() => toggleForm('addNewUser')}
                >
                    Opprett Ny bruker
                </button>
                <button
                    className={`btn btn-secondary-white-bg ${activeForm === 'editUsers' ? 'active' : ''}`}
                    onClick={() => toggleForm('editUsers')}
                >
                    Endre Bruker Info
                </button>
                <button
                    className={`btn btn-secondary-white-bg ${activeForm === 'handleData' ? 'active' : ''}`}
                    onClick={() => toggleForm('handleData')}
                >
                    Håndter Data
                </button>
            </div>
            <div>
                {activeForm === 'allUsers' && <SeeAllUsers/>}
                {activeForm === 'addNewUser' && <NewUserForm/>}
                {activeForm === 'editUsers' && <EditUsersForm/>}
                {activeForm === 'handleData' && <HandleDataDisplay/>}
            </div>
        </div>
    );
};

export default AdminDisplay;