import React, { useState, useEffect } from "react";
import { Marker } from "react-leaflet";
import mapMarkers from "../../iconsAndStyles/MapMarkers";

const RenderSanta = () => {
    const [randomCoordinate, setRandomCoordinate] = useState(null);

    const GetRandomNorthCoordinate = () => {
        const lat = (Math.random() * 20) + 70;
        const lon = (Math.random() * 360) - 160;
        return [lat, lon];
    };

    useEffect(() => {
        const initialCoordinate = GetRandomNorthCoordinate();
        setRandomCoordinate(initialCoordinate);
    }, []);

    if (!randomCoordinate) return null;
    return (
        <Marker
            position={randomCoordinate}
            icon={mapMarkers.santa}
        />
    );
};

export default RenderSanta;
