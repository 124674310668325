import bannerImg from "../../assets/images/bannerImg.png"
import christmasBannerImg from "../../assets/images/christmasBannerImg.png";

const closeMessage = () => {
    const element = document.querySelector(".info-banner");
    element.remove();
}

const InfoBanner = ({item}) => {
    const image = item === "christmas" ? christmasBannerImg : bannerImg;
    if (item === "christmas") {
        item = {
            type: "christmas",
            header: "God jul!",
            message: "I julen vil vi ha litt mindre hyppige oppdateringer enn vanlig. Vi setter pris på deres forståelse og ønsker dere en god jul og et godt nytt år!"
        }
    }

    return (
        <div className={`flex--row info-banner info-banner--${item.type}`}>
            <div className="info-banner--img flex--column justify-content-center align-items--center">
                <img src={image} alt="Illustrasjon av veier"/>
            </div>
            <div className="flex--row info-banner--content justify-content-sb ">
                <div className="flex--column info-banner--text justify-content-center align-text--left">
                    <h3>{item.header}</h3>
                    <p>{item.message}</p>
                </div>
                <p
                    className="info-banner--close-btn"
                    onClick={closeMessage}
                >
                    <b>LUKK X</b></p>
            </div>
        </div>
    );
}
export default InfoBanner;