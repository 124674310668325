import React from 'react';

const PopupNetadminHomeownerAssoc = ({item}) => {

    return (
        <>
            {item && (
                <div className="flex--column justify-content-center map--popup">
                    <div className="flex--row align-items--center map-popup-header">
                        <svg className={`icon--homeownerassoc`}/>
                        <h4>{item.name}</h4>
                    </div>

                    <div className="flex--row justify-content-sb map--popup-body">
                        <div className="flex--column justify-content-center map--popup-info">
                            <div className="flex--column tall">
                                <p><b>Antall Leiligheter:</b></p>
                                <p>{item.apartmentCount ?? 0}</p>
                            </div>
                            <div className="flex--column tall">
                                <p><b>Adresse:</b></p>
                                <p>{item.address ?? 'Ikke lagt inn'}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>

    );
};

export default PopupNetadminHomeownerAssoc;