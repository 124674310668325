import apiUrls from "../../../configs/api";

const GetCoordinatesFromAddress = async (address) => {
    try {
        const url = `${apiUrls.openstreetmap.getCoordinatesFromAddress}${encodeURIComponent(address)}`;
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
};

export default GetCoordinatesFromAddress;

