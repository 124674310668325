import React, {useEffect, useState} from "react";
import Update from "../../../../../functions/apiCalls/Update/Update";
import apiUrls from "../../../../../configs/api";
import statusOptions from "../../../../../assets/data/jsonFiles/General/statuses.json";
import HandleNotification from "../../../../../functions/notifications/HandleNotification";
import GetAll from "../../../../../functions/apiCalls/Get/GetAll";

const InfoPageEditPipeContents = ({pipe, newName, setEditMode}) => {
    const [editedPipe, setEditedPipe] = useState({
        name: newName,
        status: pipe.properties.status,
        color: pipe.properties.color,
        size: pipe.properties.size,
        notes:pipe.properties.notes,
    });
    const [pipeColors, setPipeColors] = useState(null);
    const [pipeSizes, setpipeSizes] = useState(null);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEditedPipe({ ...editedPipe, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const updatedData = {
                name: newName === '' ? pipe.properties.name : newName,
                status: editedPipe.status,
                color: editedPipe.color,
                size: editedPipe.size,
                notes: editedPipe.notes
            };

            await Update(pipe.id, apiUrls.put.updatePipeInfo, updatedData);
            await HandleNotification(pipe, 'Endret informasjonen til røret', pipe.properties.name);
            setEditMode(false);
        } catch (error) {
            console.error('Failed to update data:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const colorResponse = await GetAll(apiUrls.get.pipeColors);
                const sizeRsponse = await GetAll(apiUrls.get.pipeSizes);
                if (Array.isArray(colorResponse) && colorResponse.length > 0) {
                    setPipeColors(colorResponse);
                } else {
                    setPipeColors([]);
                }
                if (Array.isArray(sizeRsponse) && sizeRsponse.length > 0) {
                    setpipeSizes(sizeRsponse);
                } else {
                    setpipeSizes([]);
                }
                setEditedPipe((prev) => ({
                    ...prev,
                    color: editedPipe.color || colorResponse?.[0]?.name,
                    size: editedPipe.size || sizeRsponse?.[0]?.name,
                }));
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        setEditedPipe({ ...pipe.properties });
    }, [pipe]);

    return (
        <div className="info-page--content-data">
            <form className="info-page--edit-info flex--column" onSubmit={handleSubmit}>
                <label>Status</label>
                <select
                    name="status"
                    id="status"
                    value={editedPipe.status}
                    onChange={handleChange}
                >
                    {statusOptions.statusOptions.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <label>Farge</label>
                <select
                    name="color"
                    id="color"
                    value={editedPipe.color}
                    onChange={handleChange}
                >
                    {pipeColors && (
                        pipeColors.length === 0 ? (
                            <option value="">
                                Ingen farger opprettet
                            </option>
                        ) : (
                            pipeColors.map((option, index) => (
                                <option key={index} value={option.name}>
                                    {option.name}
                                </option>
                            ))
                        )
                    )}
                </select>

                <label>Størrelse</label>
                <select
                    name="size"
                    id="size"
                    value={editedPipe.size}
                    onChange={handleChange}
                >
                    {pipeSizes && (
                        pipeSizes.length === 0 ? (
                            <option value="">
                                Ingen størrelser opprettet
                            </option>
                        ) : (
                            pipeSizes.map((option, index) => (
                                <option key={index} value={option.name}>
                                    {option.name}
                                </option>
                            ))
                        )
                    )}
                </select>

                <label>Notat</label>
                <input
                    type="text"
                    id="notes"
                    name="notes"
                    className="wide-input"
                    value={editedPipe.notes}
                    onChange={handleChange}
                    placeholder="Viktig tilleggsinformasjon"
                />

                <div className="flex--row justify-content-center align-items--center">
                    <button
                        className="btn btn-secondary-white-bg"
                        type="submit"
                    >
                        Lagre Endringer
                    </button>
                </div>
            </form>

        </div>
    );
}
export default InfoPageEditPipeContents;