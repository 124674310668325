import { useEffect, useRef} from "react";
import GetById from "../../../functions/apiCalls/Get/GetById";
import apiUrls from "../../../configs/api";
import FormatChatMessage from "../../../functions/formats-and-regex/FormatChatMessage";
import { HubConnectionBuilder } from '@microsoft/signalr';
import UpdateNoId from "../../../functions/apiCalls/Update/UpdateNoId";
import { MessageDateFormat, MessageTimeFormat } from "../../../functions/formats-and-regex/MessageTimeFormat";

const ChatMessages = ({ chosenSessionId, users, messages, setMessages, usersSessions, setUsersSessions }) => {
    const userId = sessionStorage.getItem("userId");
    const bottom = useRef(null);
    const connectionRef = useRef(null);

    useEffect(() => {
        const currentUrl = window.location.pathname;
        const shouldStopConnection = currentUrl !== "/chat";

        const setupConnection = async () => {
            if (shouldStopConnection) return;
            if (connectionRef.current) {
                connectionRef.current.off("ReceiveMessage");
                await connectionRef.current.stop();
                connectionRef.current = null;
            }
            const newConnection = new HubConnectionBuilder()
                .withUrl("https://localhost:7212/Chathub")
                .withAutomaticReconnect()
                .build();

            try {
                await newConnection.start();
                console.log("WebSocket connection established.");
                if (chosenSessionId) {
                    await newConnection.invoke("JoinSession", chosenSessionId);
                    console.log(`Joined session: ${chosenSessionId}`);
                }
                newConnection.on("ReceiveMessage", (message) => {
                    const newMessage = {
                        id: message.id,
                        sessionId: message.sessionId,
                        senderId: message.senderId,
                        message: message.message,
                        date: new Date(message.date),
                        read: message.read,
                    };
                    setMessages((prevMessages) => {
                        if (!prevMessages.some((m) => m.id === newMessage.id)) {
                            return [...prevMessages, newMessage];
                        }
                        return prevMessages;
                    });
                    setUsersSessions((prevUsersSessions) =>
                        prevUsersSessions.map((session) =>
                            session.messagerId === newMessage.senderId
                                ? {
                                    ...session,
                                    lastMessage: newMessage.message,
                                    lastMessageDate: new Date(),
                                    lastMessageRead: false,
                                }
                                : session
                        )
                    );
                })
                newConnection.on("SenderMessagesRead", (messageIds) => {
                    setMessages((prevMessages) =>
                        prevMessages.map((message) =>
                            messageIds.includes(message.id)
                                ? { ...message, read: true }
                                : message
                        )
                    );
                });
                connectionRef.current = newConnection;
            } catch (error) {
                console.error("Connection failed: ", error);
            }
        };
        setupConnection();

        return () => {
            if (connectionRef.current && shouldStopConnection) {
                connectionRef.current.off("ReceiveMessage");
                connectionRef.current.stop().then(() => console.log("Connection stopped"));
                connectionRef.current = null;
            }
        };
    }, [chosenSessionId]);

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                if (chosenSessionId) {
                    const fetchedMessages = await GetById(apiUrls.chat.getChatSessionMessages, chosenSessionId);
                    if (fetchedMessages) {
                        setMessages(fetchedMessages);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchMessages();
    }, [chosenSessionId, messages]);

    useEffect(() => {
        const markMessagesAsRead = async () => {
            if (chosenSessionId) {
                const unreadMessages = messages.filter(msg =>
                    !msg.read &&
                    msg.senderId !== userId &&
                    msg.sessionId === chosenSessionId
                );

                if (unreadMessages.length > 0) {
                    const data = { ids: unreadMessages.map(msg => msg.id) };
                    try {
                        const response = await UpdateNoId(apiUrls.chat.markMessageAsRead, data);
                        if (response) {
                            setMessages((prevMessages) =>
                                prevMessages.map((msg) =>
                                    unreadMessages.some((unreadMsg) => unreadMsg.id === msg.id)
                                        ? { ...msg, read: true }
                                        : msg
                                )
                            );
                            if (connectionRef.current) {
                                await connectionRef.current.invoke("MarkMessagesAsRead", chosenSessionId, data.ids);
                            }
                        }
                    } catch (error) {
                        console.error("Failed to mark message as read:", error);
                    }
                }
            }
        };

        markMessagesAsRead();
    }, [messages, chosenSessionId, userId]);

    useEffect(() => {
        if (bottom.current) {
            bottom.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    const groupMessagesByDate = () => {
        return messages.reduce((groups, message) => {
            const dateLabel = MessageDateFormat(message.date);
            if (!groups[dateLabel]) {
                groups[dateLabel] = [];
            }
            groups[dateLabel].push(message);
            return groups;
        }, {});
    };

    const groupedMessages = groupMessagesByDate();

    return (
        <div className={`chat--messages-container ${Object.keys(groupedMessages).length < 1 && 'flex--column align-items--center justify-content-center'}`}>
            {Object.keys(groupedMessages).length > 0 ?
                Object.keys(groupedMessages).map((dateLabel, index) => (
                    <div key={index}>
                        <h3 className="chat--date-label">{dateLabel}</h3>
                        {groupedMessages[dateLabel].map((message, messageIndex, messageArray) => {
                            const senderUser = users.find(user => user.id === message.senderId);
                            const nextMessage = messageArray[messageIndex + 1];
                            const isLastInSequence = !nextMessage || nextMessage.senderId !== message.senderId;

                            return (
                                <div
                                    className={`chat--message ${message.senderId === userId ? 'you' : 'otherUser'} flex--column`}
                                    key={messageIndex}
                                >
                                    <p className="message flex--row align-items--center">
                                        {FormatChatMessage(message.message, "chat")}
                                    </p>
                                    {isLastInSequence && (
                                        <p>
                                            {message.senderId === userId
                                                ? message.read
                                                    ? 'Sett'
                                                    : 'Ikke sett'
                                                : senderUser ? `${senderUser.username} - ${MessageTimeFormat(message.date)}` : 'Bruker ikke funnet'}
                                        </p>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )) : (
                    <p className="chat--no-messages flex--row align-items--center justify-content-center">
                        Ingen meldinger her ennå – start samtalen nå!
                    </p>
                )}
            <div ref={bottom}></div>
        </div>
    );
};

export default ChatMessages;

