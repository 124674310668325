import React, {useState} from 'react';
import apiUrls from "../../../../configs/api";
import InfoMessages from "../../../messagesAndPopups/InfoMessages";
import Post from "../../../../functions/apiCalls/Post/Post";
import updateTypes from "../../../../assets/data/jsonFiles/Updates/update-types.json";

const AddUpdate = () => {
    const [formData, setFormData] = useState({
        type: 'Velg Type',
        header: '',
        message: ''
    });
    const [messageData, setMessageData] = useState();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!formData.header || !formData.message || formData.type === 'Velg Type') {
                setMessageData({
                    type: 'warning',
                    message: 'Du må fylle inn alle felt!'
                });
                return;
            }
            const formattedMessage = formatUpdate(formData.message);

            const formattedFormData = {
                ...formData,
                message: formattedMessage,
            };

            const response = await Post(apiUrls.developer.postUpdate, formattedFormData);
            if (response) {
                setFormData({
                    type: 'Velg Type',
                    header: '',
                    message: ''
                })
                setMessageData({
                    type: 'success',
                    message: 'Ny oppdatering postet.'
                });
            } else {
                setMessageData({
                    type: 'error',
                    message: 'En feil oppsto, prøv igjen.'
                });
            }
        } catch (error) {
            setMessageData({
                type: 'error',
                message: 'En feil oppsto, prøv igjen.'
            });
        }
    };

    const formatUpdate = (update) => {
        // Replace `\n` with an explicit newline symbol if needed, or just return the string as is.
        return update.replace(/\n/g, "\n");
    };


    return (
        <div className="profile--display-body higher">
            {messageData && (
                <div className="profile--display-header-no-h2">
                    <div className="profile--display-header-message">
                        <InfoMessages type={messageData.type} message={messageData.message}/>
                    </div>
                </div>
            )}
            <form className="flex--column profile--developer-add-update" onSubmit={handleSubmit}>
                <div className="flex--column justify-content-sb">
                    <label className="flex--column">
                        Oppdaterings Type
                    </label>
                    <select
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                    >
                        <option value="Velg Type">Velg Type</option>
                        {updateTypes.updateTypes.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <label className="flex--column">
                        Overskrift
                    </label>
                    <input
                        type="text"
                        name="header"
                        placeholder="Ny Oppdatering!"
                        value={formData.header}
                        onChange={handleChange}
                    />
                    <label className="flex--column">
                        Melding
                    </label>
                    <textarea
                        name="message"
                        id="message"
                        cols="66"
                        rows="2"
                        placeholder="Sjuk oppdatering kommer i morgen. Stay hydrated!"
                        value={formData.message}
                        onChange={handleChange}
                    />
                </div>

                <button className="btn btn-secondary-white-bg m-s-auto" type="submit">
                    Post Ny Oppdatering
                </button>
            </form>
        </div>
    );
};

export default AddUpdate;