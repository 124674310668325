import apiUrls from "../../../configs/api";

const LoginSms = async (bodyData, setToken, setMessageData, setSmsCode) => {
    const response = await fetch(apiUrls.login.loginUserSms, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyData)
    });
    if (response.status === 200) {
        const data = await response.json();
        setToken(data);
    }
    else {
        if (response.status === 401) {
            setMessageData({
                type: 'warning',
                message: 'Feil SMS-kode. Prøv igjen.'
            });
        } else {
            setMessageData({
                type: 'warning',
                message: 'En feil oppsto, prøv igjen senere.'
            });
        }
        setSmsCode({
            num1: '',
            num2: '',
            num3: '',
            num4: ''
        })
    }
}
export default LoginSms;