import React from "react";

const ChatUnreadSwitch = ({setSeeUnreadOnly}) => {
    return (
        <div className="chat--unread-switch flex--row align-items--center">
            <label className="switch">
                <input type="checkbox" onChange={(e) => setSeeUnreadOnly(e.target.checked)}/>
                <span className="slider round"></span>
            </label>
            <p className="flex--row align-items--center">Kun Uleste</p>
        </div>
    );
}
export default ChatUnreadSwitch;