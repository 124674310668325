import React from "react";

const HandleDataContainer = ({header, datas, isOpen,  type, toggleIsOpen, handleDelete}) => {
    const isCurrentOpen = isOpen[type];

    return (
        <div className={`profile--admin-handle-data-closeable-container ${isCurrentOpen ? 'open' : ''} flex--column justify-content-center`}>
            <div className="flex--row justify-content-sb align-items--center profile--admin-handle-data-closeable-header"
                 onClick={() => toggleIsOpen(type)}
            >
                <h4 className="flex--row align-items--center">{header}</h4>
                <svg className="icon--black-chevron"/>
            </div>
            <div className="profile--admin-handle-data-closeable flex--column">
                {datas && datas.length > 0 ? (
                    datas.map((data, index) => (
                        <div key={index} className="flex--row justify-content-sb">
                            <p>{data.name}</p>
                            <button
                                className="btn btn-delete-white-bg"
                                onClick={() => handleDelete(type, data.id)}
                            >
                                Slett
                            </button>
                        </div>
                    ))
                ) : (
                    <div className="flex--row justify-content-sb">
                        <p>Ingen opprettet</p>
                    </div>
                )}
            </div>
        </div>
    );
}
export default HandleDataContainer;