import React, {useState} from "react";
import AddNode from "../../../inputsSelectsCheckboxes/addRemoveNodes/AddNode";
import RemoveNode from "../../../inputsSelectsCheckboxes/addRemoveNodes/RemoveNode";
import FindIfFullHomeownerAssoc from "../../../../functions/page-spesific/infoPage/FindIfFullHomeownerAssoc";

const InfoPageConnectionsHomeownerAssoc = ({ homeownerAssoc, setHomeownerAssoc, header, data, userType, nodes}) => {
    const userId = sessionStorage.getItem("userId");
    const [addNodeMode, setAddNodeMode] = useState(false);
    const [removeNodeMode, setRemoveNodeMode] = useState(false);

    let buttonDisabled = homeownerAssoc && homeownerAssoc.properties.editLock && !(userId === homeownerAssoc.properties.editorLockUserId || userType === "Admin" || userType === "Utvikler");
    let allApartmentsConnected = FindIfFullHomeownerAssoc(homeownerAssoc);

    return (
        <div className='flex--column'>
            <h3>Tilkoblinger</h3>
            <div className="flex--row">
                <div className="flex--row info-page--connections-nodes">
                    <div className="flex--column info-page--connections-headers">
                        <p><b>Noder</b></p>
                    </div>
                    <div className="flex--row info-page--connections-data">
                        {nodes && nodes.length > 0 ?
                            nodes.map((node, index) => (
                                <a
                                    href={`/node/${node.id}`}
                                    key={index}
                                    className="flex--row align-items-center"
                                >
                                    {node.name}
                                </a>
                            ))
                            : (
                            <p>Ingen noder tilkoblet dette borettslaget</p>
                        ) }
                    </div>
                </div>

                {userType !== 'Read Only' && (
                    <div className="flex--row info-page--connections-update-nodes justify-content-center align-items--center">
                        {addNodeMode && (
                            <AddNode
                                addNodeMode={addNodeMode}
                                setAddNodeMode={setAddNodeMode}
                                homeownerAssocData={homeownerAssoc}
                                setHomeownerAssocData={setHomeownerAssoc}
                                nodes={nodes}
                                id={homeownerAssoc.id}
                            />
                        )}
                        {removeNodeMode && (
                            <RemoveNode
                                removeNodeMode={removeNodeMode}
                                setRemoveNodeMode={setRemoveNodeMode}
                                homeownerAssocData={homeownerAssoc}
                                setHomeownerAssocData={setHomeownerAssoc}
                                nodes={nodes}
                                id={homeownerAssoc.id}
                            />
                        )}
                        {!removeNodeMode && (
                            <button
                                className={`btn btn-secondary-white-bg ${(buttonDisabled || allApartmentsConnected) ? 'disabled' : ''} ${addNodeMode ? 'active' : ''} m-l-10px `}
                                onClick={() => setAddNodeMode(prevMode => !prevMode)}
                                disabled={(buttonDisabled || allApartmentsConnected)}
                            >
                                {addNodeMode ? 'Avbryt' : 'Koble Til Node'}
                            </button>
                        )}
                        {!addNodeMode && (
                            <button
                                className={`btn btn-delete-white-bg ${buttonDisabled ? 'disabled' : ''} ${removeNodeMode ? 'active' : ''} m-l-10px `}
                                onClick={() => setRemoveNodeMode(prevMode => !prevMode)}
                                disabled={buttonDisabled}
                            >
                                {removeNodeMode ? 'Avbryt' : 'Fjern Node'}
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
export default InfoPageConnectionsHomeownerAssoc;