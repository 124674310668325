import HeaderLoggedIn from "../../components/headers/headerLoggedIn";
import React, { useEffect, useState } from "react";
import ChatSendMessage from "../../components/inputsSelectsCheckboxes/ChatSendMessage";
import ChatMessages from "../../components/containers/chat/ChatMessages";
import ChatAllUserChats from "../../components/containers/chat/ChatAllUserChats";
import GetAll from "../../functions/apiCalls/Get/GetAll";
import apiUrls from "../../configs/api";
import ChatUnreadSwitch from "../../components/inputsSelectsCheckboxes/ChatUnreadSwitch";
import ChatSearchUsers from "../../components/containers/chat/ChatSearchUsers";
import { useSearchParams } from "react-router-dom";

const ChatPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const initialChatId = searchParams.get("chat");
    const [chosenUserId, setChosenUserId] = useState("");
    const [chosenSessionId, setChosenSessionId] = useState(initialChatId || "");
    const [usersSessions, setUsersSessions] = useState([]);
    const [messages, setMessages] = useState([]);
    const [users, setUsers] = useState([]);
    const [seeUnreadOnly, setSeeUnreadOnly] = useState(false);
    const [userSearchInput, setUserSearchInput] = useState("");

    useEffect(() => {
        const fetchUsers = async () => {
            const users = await GetAll(apiUrls.get.users);
            if (users) {
                setUsers(users);
            }
        };
        fetchUsers();
    }, []);

    useEffect(() => {
        const fetchSessions = async () => {
            const sessions = await GetAll(apiUrls.chat.getUsersSessions);
            if (sessions) {
                setUsersSessions(sessions);
            }
        };
        fetchSessions();
    }, [users]);

    useEffect(() => {
        if (!chosenSessionId || !usersSessions.length) return;
        const session = usersSessions.find(session => session.chatSessionId === chosenSessionId);
        if (session) {
            setChosenUserId(session.messagerId);
        } else {
            console.warn("Session not found for chosenSessionId:", chosenSessionId);
        }
    }, [chosenSessionId, usersSessions]);

    useEffect(() => {
        if (chosenSessionId) {
            setSearchParams({ chat: chosenSessionId });
        }
    }, [chosenSessionId, setSearchParams]);

    return users && usersSessions && (
        <div className="chat">
            <HeaderLoggedIn />
            <div className="flex--row">
                <div className="chat--all flex--column align-items--center">
                    <ChatSearchUsers users={users} userSearchInput={userSearchInput} setUserSearchInput={setUserSearchInput} seeUnreadOnly={seeUnreadOnly} />
                    <ChatUnreadSwitch setSeeUnreadOnly={setSeeUnreadOnly} />
                    <ChatAllUserChats usersSessions={usersSessions} setUsersSessions={setUsersSessions} setChosenUserId={setChosenUserId} users={users} chosenSessionId={chosenSessionId} setChosenSessionId={setChosenSessionId} seeUnreadOnly={seeUnreadOnly} userSearchInput={userSearchInput} messages={messages}/>
                </div>
                <div className="chat--open flex--column justify-content-center align-items--center">
                    {!chosenSessionId ? (
                        <div className="chat--no-open-chat flex--column justify-content-center align-items--center">
                            <h3>Velg en chat for å komme i gang!</h3>
                            <p>Du har ikke åpnet en chat ennå. Klikk på en av samtalene i sidemenyen for å starte chatting.</p>
                        </div>
                    ) : (
                        <>
                            <ChatMessages messages={messages} setMessages={setMessages} chosenSessionId={chosenSessionId} users={users} usersSessions={usersSessions} setUsersSessions={setUsersSessions} />
                            <ChatSendMessage receiverId={chosenUserId} usersSessions={usersSessions} setUsersSessions={setUsersSessions}/>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChatPage;
