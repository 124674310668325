const RegexAddress = (address) => {
    const addressRegex = /^[a-zA-ZÀ-ÿ\s.\`\´\-']+ \d{1,5}\s?[a-zA-Z]? *,? *\d{4} *[a-zA-ZÀ-ÿ\s.\`\´\-']*$/;

    return addressRegex.test(address);
};

export default RegexAddress;



