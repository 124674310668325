import React, {useEffect, useState} from "react";

import apiUrls from "../../../configs/api";
import axios from "axios";
import IsDecember from "../../../functions/checks/IsDecember";

const Search = ({onResultHover}) => {
    const [keyword, setKeyword] = useState("");
    const [results, setResults] = useState([]);
    const [placeholder, setPlaceholder] = useState("Etterstad Slottet");

    const christmasAreas = ["Nordpolen", "Nissens Verkssted", "Reinsdyrsletten", "Lappland", "Pepperkakebyen", "Blåfjell", "Svingen"];

    useEffect(() => {
        if (IsDecember()) {
            const randomPlaceholder = christmasAreas[Math.floor(Math.random() * christmasAreas.length)];
            setPlaceholder(randomPlaceholder);
        }
    }, []);

    const handleChange = async (event) => {
        const newKeyword = event.target.value;
        setKeyword(newKeyword);
        if (newKeyword.length >= 2) {
            try {
                const token = sessionStorage.getItem('token');
                const config = {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                };
                const response = await axios.get(`${apiUrls.search.search}?keyword=${newKeyword}`, config);
                setResults(response.data.results);
            } catch (error) {
                console.error("Error fetching search results:", error);
            }
        } else {
            setResults([]);
        }
    };
    const handleResultHover = (result) => {
        onResultHover(result);
    };
    const getLink = (id, type) => {
        switch (type) {
            case "Kum":
                return `kum/${id}`;
            case "Node":
                return `node/${id}`;
            case "Adresse":
                return `adresse/${id}`;
            case "Kabel":
                return `kabel/${id}`;
            case "Rør":
                return `ror/${id}`;
            case "Borettslag":
                return `borettslag/${id}`;
            default:
                return "#";
        }
    };

    return (
        <div className="map--search">
            <div className="input flex--row justify-content-sb align-items--center">
                <input
                    type="text"
                    placeholder={placeholder}
                    value={keyword}
                    onChange={handleChange}
                    className="search-input"
                />
                <svg className="icon--green-search"/>
            </div>
            {keyword && keyword.length >= 2 && results && (
                <div className="map--search-results">
                    {results.length > 0 ? (
                        <>
                            <div className="results-header flex--row">
                                <p>Resultater: </p><p>{' '}&nbsp;{results.length}</p>
                            </div>
                            <div className="results-responses">
                                {results.map((result, index) => (
                                    <div key={index}>
                                        <a
                                            href={getLink(result.id, result.properties ? result.properties.type : result.type) || "#"}
                                            className="search--result btn-link"
                                            key={result.id}
                                            onMouseEnter={() => handleResultHover(result)}
                                        >
                                            {result.name || (result.address && result.unitNumber && (result.address + ", " + result.unitNumber)) || result.properties.name}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <p className="results-none">Ingen treff ble funnet.</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default Search;
