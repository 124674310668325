import React, {useEffect, useState} from 'react';
import statusOptions from '../../../../assets/data/jsonFiles/General/statuses.json';
import GetAll from "../../../../functions/apiCalls/Get/GetAll";
import apiUrls from "../../../../configs/api";

const NewConnectionInputs = ({ handleInputChange, formData, setFromData }) => {
    const [connectionPointTypes, setConnectionPointTypes] = useState(null);
    const [spliceEnclosureTypes, setspliceEnclosureTypes] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const cptResponse = await GetAll(apiUrls.get.connectionPointTypes);
            const setResponse = await  GetAll(apiUrls.get.spliceEnclosureTypes);
            if (cptResponse && setResponse) {
                setConnectionPointTypes(cptResponse);
                setspliceEnclosureTypes(setResponse);
                setFromData(prevFormData => ({
                    ...prevFormData,
                    kumType: cptResponse[0]?.name || '',
                    spliceEnclosure: setResponse[0]?.name || 'Ingen'
                }));
            }
        }
        fetchData();
    }, []);

    return (
        <div className="map--add-form-inputs flex--column">
            <div className='flex--row justify-content-sb map--add-form-input-row'>
                <div className='flex--column map--add-form-input-container'>
                    <label>Breddegrad</label>
                    <input
                        type='text'
                        id='latitude'
                        name='latitude'
                        placeholder='59.902712954570134'
                        value={formData.latitude}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFromData)}
                    />
                </div>
                <div className='flex--column map--add-form-input-container'>
                    <label>Lengdegrad</label>
                    <input
                        type='text'
                        id='longitude'
                        name='longitude'
                        placeholder='10.783520510856157'
                        value={formData.longitude}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFromData)}
                    />
                </div>
            </div>
            <div className='flex--row justify-content-sb map--add-form-input-row'>
                <div className='flex--column map--add-form-input-container'>
                    <label>Type</label>
                    <select
                        name="kumType"
                        id="kumType"
                        value={formData.kumType}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFromData)}
                    >
                        {connectionPointTypes && (
                            connectionPointTypes.length === 0 ? (
                                <option value="" disabled>
                                    Ingen typer opprettet
                                </option>
                            ) : (
                                connectionPointTypes.map((option, index) => (
                                    <option key={index} value={option.name}>
                                        {option.name}
                                    </option>
                                ))
                            )
                        )}
                    </select>
                </div>
                <div className='flex--column map--add-form-input-container'>
                    <label>Skjøte</label>
                    <select
                        name="spliceEnclosure"
                        id="spliceEnclosure"
                        value={formData.spliceEnclosure}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFromData)}
                    >
                        <option value="Ingen">
                            Ingen
                        </option>
                        {spliceEnclosureTypes && (
                            spliceEnclosureTypes.length === 0 ? (
                                <option value="" disabled>
                                    Ingen typer opprettet
                                </option>
                            ) : (
                                spliceEnclosureTypes.map((option, index) => (
                                    <option key={index} value={option.name}>
                                        {option.name}
                                    </option>
                                ))
                            )
                        )}
                    </select>
                </div>
            </div>
            <div className='flex--row justify-content-sb map--add-form-input-row'>
            <div className='flex--column map--add-form-input-container'>
                    <label>Navn</label>
                    <input
                        type='text'
                        id='name'
                        name='name'
                        placeholder='Tilkoblingspunktet'
                        value={formData.name}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFromData)}
                    />
                </div>
                <div className='flex--column map--add-form-input-container'>
                    <label>Status</label>
                    <select
                        name="status"
                        id="status"
                        value={formData.status}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFromData)}
                    >
                        {statusOptions.statusOptions.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className='flex--row justify-content-sb map--add-form-input-row'>
                <div className='flex--column whole-row'>
                    <label>Notater</label>
                    <input
                        type='text'
                        id='notes'
                        name='notes'
                        placeholder='Viktig tilleggsinformasjon'
                        value={formData.notes}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value, setFromData)}
                    />
                </div>
            </div>
        </div>
    );
}
export default NewConnectionInputs;